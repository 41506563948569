import React, { useEffect } from 'react'
import { useNavigate, useParams, Link } from "react-router-dom";
import FormComponent from '../../../Components/FormComponent';
import styles from "../FundForm.module.scss"
import {
    useCreateExchangeAccount,
    useEditExchangeAccount,
    useExchangeAccount
} from "../../../query-hooks/exchange-query-hooks/useExchange";
import ExchangeAccountForm from "../../Exchanges/ExchangeAccounts/ExchangeAccountForm";
import exchangeAccountForm from "../../Exchanges/ExchangeAccounts/ExchangeAccountForm";
import {useQueryClient} from "react-query";

const FundExchangeAccountMutation = () => {
    const navigate = useNavigate();
    const { fundId, exchangeAccountId } = useParams()
    const exchangeAccount = useExchangeAccount(exchangeAccountId)
    const createExchangeAccount = useCreateExchangeAccount()
    const editExchangeAccount = useEditExchangeAccount()
    const onSubmit = data => {
        data.fundId = fundId
        if(exchangeAccountId) {
            editExchangeAccount.mutate({  exchangeAccount: data, exchangeAccountId: exchangeAccountId}).then()
        }
        createExchangeAccount.mutate(data)
    }
    useEffect(() => { createExchangeAccount.isSuccess && navigate(-1) }, [createExchangeAccount])
    useEffect(() => { editExchangeAccount.isSuccess && navigate(-1) }, [editExchangeAccount])

    return (
        <FormComponent title="Fund Exchange Accounts" subtitle={exchangeAccountId ? "Edit Exchange Account" : "Create Exchange Account"}>
            { !exchangeAccountId && <ExchangeAccountForm data={{fundId: fundId}} hideFund={true} onSubmit={onSubmit} /> }
            { exchangeAccount.isSuccess && <ExchangeAccountForm hideFund={true} hideExchange={true} onSubmit={onSubmit} data={{...exchangeAccount.data, exchangeId: exchangeAccount.data.exchange?.id, fundId}} /> }
            { /*bankAccountId && <Link className={styles.createButton} to={`/main/funds/bank-account/balances/create-balance/${bankAccountId}`}>Add Balance</Link> */}
            { /*bankAccountId && <BankAccountBalancesDashboard bankAccountId={bankAccountId}  /> */}
        </FormComponent>
    );
};
export default FundExchangeAccountMutation
