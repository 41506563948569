import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ExchangeAccountForm from "./ExchangeAccountForm";
import { useCreateExchangeAccount, useExchangeAccount, useEditExchangeAccount } from "../../../query-hooks/exchange-query-hooks/useExchange"
import FormComponent from "../../../Components/FormComponent"

const ExchangeAccountFormMutation = () => {
  const { exchangeId, exchangeAccountId } = useParams();
  const navigate = useNavigate();
  const exchangeAccount = useExchangeAccount(exchangeAccountId)
  const createExchangeAccount = useCreateExchangeAccount();
  const editExchangeAccount = useEditExchangeAccount()
  const onSubmit = data => {
    if(data.fundId === "empty") data.fundId = null;
    data.exchangeId = exchangeId;
    if (exchangeAccountId) {
      return editExchangeAccount.mutate( {exchangeAccount: data, exchangeAccountId: exchangeAccountId  } );
    }
    return createExchangeAccount.mutate(data);
  };
  useEffect(() => { createExchangeAccount.isSuccess && navigate(-1) }, [createExchangeAccount])
  useEffect(() => { editExchangeAccount.isSuccess && navigate(-1) }, [editExchangeAccount])
  return (
    <FormComponent title="Exchange Account Dashboard" subtitle={exchangeAccountId ? "Edit Exchange Account" : "Create Exchange Account"}>
      { !exchangeAccountId && <ExchangeAccountForm hideExchange={true} onSubmit={onSubmit} /> }
      { exchangeAccount.isSuccess && <ExchangeAccountForm hideExchange={true} onSubmit={onSubmit} data={ {...exchangeAccount.data, exchangeId: exchangeAccount.exchange?.Id} } /> }
    </FormComponent>
  );
};
export default ExchangeAccountFormMutation