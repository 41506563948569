import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import { Form, Row, Col } from "react-bootstrap";
import styles from "./TransferForm.module.scss";
import { useAuth } from '../../Hooks/useAuth';
import { useFundHoldingsByBookingPeriod, useFundHolding } from "../../query-hooks/fund-query-hooks/useFund"
import { TRANSACTIONTYPES } from '../../constants/enums';
import TransferFormGroupInput from '../../Components/FormInputs/TransferFormGroupInput';
import CurrencyPopup from './CurrencyPopup';
import ReservationsList from './ReservationsList';
import { ImportExplorers } from './ImportExplorers/ImportExplorers';
import ModalComponent from '../../Components/ModalComponent';
import FundsHoldingCreate from '../Funds/FundsHoldings/FundsHoldingCreate';
import { setBookingPeriodDateInput } from '../../Helpers/Helpers';

const TransferForm = ({ onSubmit, fundId, importTransferExplorer, setImportTransferExplorer, setFundings, transfersInflow, fundData, bookingPeriod, action }) => {
    const { register, setValue, handleSubmit, watch, formState: { errors } } = useForm( { defaultValues: { fromHoldingId: null, exchangeRate: 0 } });
    const { darkmode, transferFormProps, setTransferFormProps } = useAuth()
    const [ transType, setTransType ] = useState()
    const [ filterUnused, setFilterUnused ] = useState(false)
    const [ selectedHoldingId, setSelectedHoldingId ] = useState()
    const [ selectedHoldingDisplayName, setSelectedHoldingDisplayName ] = useState()
    const [ date, setDate ] = useState()
    const [ amountTicker, setAmountTicker ] = useState()
    const [ feeHoldingTicker, setFeeHoldingTicker ] = useState()
    const fundHoldings = useFundHoldingsByBookingPeriod(fundId, filterUnused, bookingPeriod)
    const fundHolding = useFundHolding(fundId, selectedHoldingId)
    const [ openHoldingFormModal, setOpenHoldingFormModal ] = useState(false)
    // Explorer Import States
    const [ importData, setImportData ] = useState()

    // Function to handle holding selection in dropdown input 
    const onHandleSelectedHolding = target => {
        setSelectedHoldingDisplayName(target.options[target.selectedIndex].text)
        setSelectedHoldingId(target.value)
        setValue("fromHoldingId", target.value)
        setValue("feeHoldingId", target.value)
        const amountCoin = fundHoldings.data.find(holding => holding.id === target.value)
        setAmountTicker(amountCoin && amountCoin.symbol)
        setFeeHoldingTicker(amountCoin && amountCoin.symbol)
    }

    // Function to handle import Explorer data 
    const handleImportExplorer = data => {
        setImportTransferExplorer(data)
        const { explorer } = data
        setDate(new Date(explorer.dateTime).toISOString().slice(0, 16))
        setImportData(explorer)
        setAmountTicker(explorer.asset.symbol.length < 9 ? explorer.asset.symbol : null)  // Filter to display holding symbol in box length of 8 or less
        setFeeHoldingTicker(explorer.feeCurrency.symbol.length < 9 ? explorer.feeCurrency.symbol : null)  // Filter to display fee currency symbol in box length of 8 or less
        setValue("transferAmount", explorer.transferAmount)
        setValue("transferFee", explorer.transferFee)
        setValue("transactionId", explorer.transactionId)
        setValue("fromAddress", explorer.fromAddress)
        setValue("toAddress", explorer.toAddress)
    }

    const onHandleFeeTicker = feeHoldingId => {
        const feeCoin = fundHoldings.data.find(holding => holding.id === feeHoldingId)
        setFeeHoldingTicker(feeCoin && (feeCoin.cryptoCurrency ? feeCoin.cryptoCurrency.symbol : feeCoin.currency.isoCode) )
    }

    // Open Create Holding Form Modal 
    const openCreateHoldingModalFn = e => {
        e.preventDefault()
        setOpenHoldingFormModal(true)
    }

    // When mounting render, if there is no transfer selected properties stored in LocalStorage then set date as the first day of selected Booking Period
    useEffect(() => { 
        if (!transferFormProps) {
            setDate(setBookingPeriodDateInput(bookingPeriod))
            setValue("dateTime", setBookingPeriodDateInput(bookingPeriod))
        } 
    }, [ bookingPeriod, transferFormProps ])
    
    // Reset stored state values when rerendering component
    useEffect(() => { setTransferFormProps(null) }, [])

    // Load transfer pre defined properties if they exist. Keeping previous state for selected properties
    useEffect(() => { 
        if (transferFormProps) {
            setValue("transactionType", transferFormProps.transactionType)
            setTransType(transferFormProps.transactionType)
            setValue("toHoldingId", transferFormProps.toHoldingId)
            setValue("dateTime", transferFormProps.dateTime)
            setDate(transferFormProps.dateTime)
        } 
    }, [ transferFormProps ])

    // Flow to calculate fields dynamically: Transfer Amount, Received Amount & Exchange Rate
    const getTransferAmountOnChange = value => { 
        const watchExchangeRate = watch("exchangeRate")
        if (watchExchangeRate) return setValue("receivedAmount", value * watchExchangeRate) 
    }

    const getReceivedAmountOnChange = value => {
        const watchTransferAmount = watch("transferAmount")
        if (watchTransferAmount) return setValue("exchangeRate", value / watchTransferAmount)
    }

    const getExchangeRateOnChange = value => {
        const watchTransferAmount = watch("transferAmount")
        if (watchTransferAmount) return setValue("receivedAmount", value * watchTransferAmount)
    }
    
    return (
        <div className='px-5'>
            <Row>
                <Col xl={6}>
                    <h2 className={styles.formTitle}>{ action }: <span>{ fundData.fundName }</span></h2>
                </Col>
                <ImportExplorers handleImportExplorer={handleImportExplorer} importData={importData} setFundings={setFundings} /> 
            </Row>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col xl={6} md={12} className="py-1">
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Select a Transaction Type</h6>
                        <select className={styles.selectInput} {...register("transactionType", { required: !importData })} onChange={e => setTransType(e.target.value)}>
                            { TRANSACTIONTYPES.map(transaction => ( <option key={transaction.value} value={transaction.value} >{transaction.name}</option> ))} 
                        </select> 
                    </Col>
                    <Col xl={6} md={12}>
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Select a Date and Time <em className={styles.reqSymbol}>*</em></h6>
                        <input className={styles.datetime} type="datetime-local" defaultValue={date} min="2018-06-07T00:00" max="2025-06-14T00:00" {...register("dateTime", { required: !importData })} disabled={importData} />
                        {errors.dateTime && errors.dateTime.type === "required" && (<span className={styles.formErrorNotice}>You must choose a source</span>)}
                    </Col>
                </Row>

                {/* Balance Popup */}
                <CurrencyPopup data={fundHolding.isSuccess && fundHolding.data} setValue={setValue} />

                {/* Is Reservation */}
                <Row className="d-flex justify-content-center">
                    <Col xs={12}>
                        <label className={styles.checkbox}>
                            <span>Is Reservation?</span>
                            <input type="checkbox" {...register("isReservation")} />
                        </label>
                    </Col>
                </Row>

                <Row>
                    <Col xl={6} md={12}>
                        <h6 className={darkmode ? styles.inputLabelDark : styles.inputLabel }>Select a Source Holding <em className={styles.reqSymbol}>*</em></h6>
                        {   importData ? <input className={styles.fundFormInputs} value={importData.asset.name} disabled /> :
                                <div style={{position:"relative"}}>
                                    <select className={styles.selectInput} {...register("fromHoldingId", { required: !importData, pattern: /^.{2,}$/ })} onChange={e => onHandleSelectedHolding(e.target)}>
                                        <option value="1"></option>
                                        { fundHoldings.isSuccess && fundHoldings.data.map(holding => ( <option key={holding.id} value={holding.id} >{ holding.displayName }</option> ))} 
                                    </select> 
                                    <button className={styles.selectInputButton} onClick={openCreateHoldingModalFn}>Create</button>
                                    {errors.fromHoldingId && errors.fromHoldingId.type === "required" && (<span className={styles.formErrorNotice}>You must choose a source</span>)}
                                    {errors.fromHoldingId && errors.fromHoldingId.type === "pattern" && (<span className={styles.formErrorNotice}>You must choose a source</span>)}
                                </div>
                        }
                    </Col>
                    <Col xl={6} md={12}>
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Select a Fee Holding<em className={styles.reqSymbol}>*</em></h6>
                        {   importData ? <input className={styles.fundFormInputs} value={importData.feeCurrency.name} disabled /> :
                            <>
                                <select className={styles.selectInput} {...register("feeHoldingId", { required: !importData, pattern: /^.{2,}$/ })} onChange={(e) => fundHoldings.isSuccess && onHandleFeeTicker(e.target.value)}>
                                    <option></option>
                                    { fundHoldings.isSuccess && fundHoldings.data.map(holding => ( <option key={holding.id} value={holding.id} >{ holding.displayName }</option> ))} 
                                </select> 
                                {errors.feeHoldingId && errors.feeHoldingId.type === "required" && (<span className={styles.formErrorNotice}>You must choose a source</span>)}
                                {errors.feeHoldingId && errors.feeHoldingId.type === "pattern" && (<span className={styles.formErrorNotice}>You must choose a source</span>)}
                            </>
                        }
                    </Col>
                </Row>

                <Row className="d-flex justify-content-center">
                    <TransferFormGroupInput title="Enter a Transfer Amount" fieldName="transferAmount" register={register} errors={errors} inputText={amountTicker} placeHolder="Enter Transfer Amount" isRequired={!importData} disabled={importData} getTransferAmountOnChange={getTransferAmountOnChange} />
                    <TransferFormGroupInput title="Enter a Transfer Fee" fieldName="transferFee" register={register} errors={errors} inputText={feeHoldingTicker} placeHolder="Enter Transfer Fee" isRequired={!importData} disabled={importData} />
                </Row>
                {/* Transaction Type display conditions */}
                {
                    (transType === "Broker" || transType === "Transfer" ) &&
                    <>
                        <Row>
                            <Col xl={6} md={12} className="py-1">
                                <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Select a destination Holding</h6>
                                <div style={{position:"relative"}}>
                                    <select className={darkmode ? styles.selectInputDark : styles.selectInput} {...register("toHoldingId", { required: !importData })} onChange={e => e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text.includes("(Shares)") && setValue("exchangeRate", 0)}>
                                        <option></option>
                                        { fundHoldings.isSuccess && fundHoldings.data.map(
                                            holding => ( <option key={holding.id} value={holding.id} >{ holding.displayName }</option> 
                                            ))} 
                                    </select> 
                                    <button className={styles.selectInputButton} onClick={openCreateHoldingModalFn}>Create</button>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6} md={12}>
                                <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Received Amount <em className={styles.reqSymbol}>*</em></h6>
                                <input className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} step="any" placeholder="Enter a Received Amount" {...register("receivedAmount", { required: true, valueAsNumber: true })} onChange={e => getReceivedAmountOnChange(e.target.value)} />
                                {errors.receivedAmount && errors.receivedAmount.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                            </Col>
                            <Col xl={6} md={12}>
                                <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter an Exchange Rate<em className={styles.reqSymbol}>*</em></h6>
                                <input className={styles.fundFormInputs} type="number" defaultValue={0} onWheel={e => e.currentTarget.blur()} step="any" placeholder="Enter an exchange rate" {...register("exchangeRate", { required: !importData, valueAsNumber: true })} onChange={e => getExchangeRateOnChange(e.target.value)} />
                                {errors.exchangeRate && errors.exchangeRate.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                            </Col>
                        </Row>
                    </>
                }
                <Row>
                    <Col xl={6} md={12}>
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a Transaction Id <em className={styles.reqSymbol}>*</em></h6>
                        <input className={styles.fundFormInputs} placeholder="Transaction Id" {...register("transactionId", { required: !importData, maxLength: 128})} disabled={importData} />
                        {errors.transactionId && errors.transactionId.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                        {errors.transactionId && errors.transactionId.type === "maxLength" && (<span className={styles.formErrorNotice}>Max of 128 characters has been exceeded</span>)}
                    </Col>
                    {/* Shares Input Display Conditions */}
                    { (transType === "Inflow" || transType === "Outflow" || transType === "Correction") &&
                        <Col xl={6} md={12} className="py-1">
                            <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter Shares <em className={styles.reqSymbol}>*</em></h6>
                            <input className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} step="any" placeholder="Shares" {...register("shares", { valueAsNumber: true})} />
                        </Col> 
                    }
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col xl={6} md={12} className="py-1">
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a source Address <em className={styles.reqSymbol}>*</em></h6>
                        <input className={styles.fundFormInputs} placeholder="Source Address" {...register("fromAddress", { required: !importData, maxLength: 64})} disabled={importData} />
                        {errors.fromAddress && errors.fromAddress.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                        {errors.fromAddress && errors.fromAddress.type === "maxLength" && (<span className={styles.formErrorNotice}>Max of 64 characters has been exceeded</span>)}
                    </Col>
                    <Col xl={6} md={12} className="py-1">
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a Destination Address</h6>
                        <input className={styles.fundFormInputs} placeholder="Destination Address" {...register("toAddress", { maxLength: 64})} disabled={importData} />
                        {errors.toAddress && errors.toAddress.type === "maxLength" && (<span className={styles.formErrorNotice}>Max of 64 characters has been exceeded</span>)}
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col xl={(transType === "Outflow" && fundData.performanceFeePolicy === "InflowDate") ? 6 : 12} md={12} className="py-1">
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a Reference</h6>
                        <input className={styles.fundFormInputs} placeholder="Reference (Optional)" {...register("reference")} />
                    </Col>
                    {   (transType === "Outflow" && fundData.performanceFeePolicy === "InflowDate") &&
                        <Col xl={6} md={12} className="py-1">
                            <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Select an Inflow Transfer</h6>
                            <select className={darkmode ? styles.selectInputDark : styles.selectInput} {...register("inflowTransferId", { required: true })}>
                                <option value=""></option>
                                { transfersInflow.isSuccess && transfersInflow.data.map(transferInflow => ( 
                                    <option key={transferInflow.id} value={transferInflow.id} >Booking Period: { transferInflow.bookingPeriod } / Shares: { transferInflow.shares }</option> 
                                ))} 
                            </select>
                            {errors.inflowTransferId && errors.inflowTransferId.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                        </Col>
                    }
                </Row>

                {   transType === "BookReservations" && <ReservationsList fundId={fundId} setValue={setValue} selectedHoldingDisplayName={selectedHoldingDisplayName} importTransferExplorer={importTransferExplorer} /> }
                
                <Row className="d-flex justify-content-center">
                    <Col lg={4} md={12}>
                        <input className={styles.submitButton} type="submit" />
                    </Col>
                </Row>
            </Form>
            {/* Modal to display dynamic Holding Form */}
            { openHoldingFormModal && <ModalComponent stateTrigger={setOpenHoldingFormModal}><FundsHoldingCreate closeModalMode={setOpenHoldingFormModal} transferModalFundId={fundId} /></ModalComponent> } 

        </div>
    )
}
export default TransferForm