import styles from "./MarketDepthPage.module.scss";
import { numberEditor } from "../../Helpers/Helpers";
import { Tab, Tabs } from "react-bootstrap";
import AggregateChart from "./Tables/AggregateChart";
import HistoryChart from "./Tables/HistoryChart";
import React from "react";
import {useAuth} from "../../Hooks/useAuth";

function NaNNumberEditor(value) {
  return isNaN(value) || value == null ? "?" : numberEditor(value);
}

export function MarketDepthLine({holding, cryptoGuid, clickHandler}) {
  const {darkmode} = useAuth();
  return (
    <React.Fragment
      key={holding.id}
    >
    <tbody onClick={()=>clickHandler(holding.cryptoCurrency?.id)}>
      <tr
        className={`${darkmode ? styles.tableStripsDark : styles.tableStrips} align-middle `}
      >
        <td>{holding.symbol}</td>
        <td>${NaNNumberEditor(holding.usdLiquidityTwoPercentDown)}</td>
        <td>${NaNNumberEditor(holding.usdLiquidityFivePercentDown)}</td>
        <td>${NaNNumberEditor(holding.usdLiquidityTenPercentDown)}</td>
        <td>${NaNNumberEditor(holding.maxAllocation)}</td>
        <td>${numberEditor(holding.endUSDValue)}</td>
        <td>{NaNNumberEditor(holding.overUnderAllocationPct)}%</td>
        <td>{NaNNumberEditor(holding.pctHoldingsLiquidatable)}%</td>
        <td>${NaNNumberEditor(holding.usdLiquidatable)}</td>
      </tr>
    </tbody>
      {holding.cryptoCurrency?.id != null && holding.cryptoCurrency?.id === cryptoGuid && (
        <tbody
          key={`${holding.id}-depth`}
        >
        <tr
          className={`${darkmode ? styles.tableStripsDark : styles.tableStrips} align-middle `}
        >
          <td colSpan="9">
            <Tabs fill mountOnEnter={true} unmountOnExit={true}>
              <Tab eventKey="current" title="Current">
                <AggregateChart
                  cryptoGuid={holding.cryptoCurrency.id}
                  listingPrice={holding.endUSDPrice}
                />
              </Tab>
              <Tab eventKey="history" title="History">
                <HistoryChart id={holding.cryptoCurrency.id} />
              </Tab>
            </Tabs>
          </td>
        </tr>
        </tbody>
      )}
    </React.Fragment>
  );
}
