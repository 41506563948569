import React, { useState } from "react";
import {Tab, Table, Tabs} from "react-bootstrap";
import styles from "./AllCryptos.module.scss";
import { Link } from "react-router-dom";
import PaginationAPI from "../../Components/PaginationAPI";
import {
  useDeleteCrypto,
  confirmationAlertDeleteCrypto,
} from "../../query-hooks/crypto-query-hooks/useCryptos";
import { useAuth } from "../../Hooks/useAuth";
import Logo from "../../images/Hodl-logo.jpg";
import { API_URL } from "../../settings";
import { numberEditor } from "../../Helpers/Helpers";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";

const AllCryptos = ({ cryptos, setPage }) => {
  const { darkmode } = useAuth();
  const [currentItems, setCurrentItems] = useState();
  const getCurrentItems = (updatedItems) => setCurrentItems(updatedItems);
  const mutation = useDeleteCrypto();
  const handleImageError = (e) => (e.target.src = Logo); // Handles img src error

  return (
    <>
      <Table responsive borderless={true} className={styles.table}>
        <thead>
          <tr className={darkmode ? styles.theaderDark : styles.theader}>
            <th></th>
            <th></th>
            <th>LISTING BTC PRICE</th>
            <th>LISTING USD PRICE</th>
          </tr>
        </thead>
        {currentItems &&
          currentItems.map((crypto) => (
            <React.Fragment key={crypto.id}>
              <tbody key={crypto.id}>
                <tr
                  className={`${darkmode ? styles.tableStripsDark : styles.tableStrips} align-middle `}
                >
                  <td>
                    <img
                      alt="icon"
                      key={Date.now()}
                      src={`${API_URL}/cryptos/${crypto.id}/icon?t=${Date.now()}`}
                      width="47px"
                      onError={handleImageError}
                      className={styles.cryptoIcons}
                    />
                  </td>
                  <td
                    style={{
                      textAlign: "left",
                      fontSize: "15px",
                      color: "#F6F3EE",
                    }}
                  >
                    {crypto.name}{" "}
                    <span
                      style={{
                        display: "block",
                        fontSize: "12px",
                        color: "#b6b6b6",
                      }}
                    >
                      {crypto.symbol.toUpperCase()}
                    </span>
                  </td>
                  <td
                    style={{ fontSize: "13px" }}
                    title={crypto.listing && crypto.listing.btcPrice}
                  >
                    {crypto.listing && numberEditor(crypto.listing.btcPrice)}
                  </td>
                  <td
                    style={{ fontSize: "13px" }}
                    title={crypto.listing && crypto.listing.usdPrice}
                  >
                    {crypto.listing && numberEditor(crypto.listing.usdPrice)}
                  </td>
                  <td className={styles.tooltip}>
                    <Link
                      className={styles.icons}
                      to={`edit-crypto/${crypto.id}`}
                    >
                      <AiOutlineEdit />
                      <span className={styles.tooltiptext}>Edit</span>
                    </Link>
                  </td>
                  <td
                    className={styles.tooltip}
                    onClick={(e) =>
                      confirmationAlertDeleteCrypto(e, crypto.id, mutation)
                    }
                  >
                    <AiOutlineDelete className={styles.icons} />
                    <span className={styles.tooltiptext}>Delete</span>
                  </td>
                </tr>
              </tbody>

            </React.Fragment>
          ))}
      </Table>
      <PaginationAPI
        data={cryptos.isSuccess && cryptos.data}
        getCurrentItems={getCurrentItems}
        setPage={setPage}
      />
    </>
  );
};
export default AllCryptos;
