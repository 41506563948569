import React, {useEffect, useState} from "react";
import {
    useRemoteExchanges
} from "../../query-hooks/external-query-hooks/useExternal";
import styles from "../Cryptos/CryptoSearchInput.module.scss";
import {useAuth} from "../../Hooks/useAuth";

const ExchangeSearchInput = ({source, reg: {onChange, onBlur, name, ref}, value}) => {
    const {darkmode} = useAuth();
    const [inputValue, setInputValue] = useState(value);
    const [selectedExchanges, setSelectedExchanges] = useState([]);

    const exchangesAPI = useRemoteExchanges(source);

    useEffect(() => {
        if (onChange) {
            onChange({ target: { name, ref, value: inputValue } });
        }
    }, [inputValue, onChange, name]);

    const getCryptobyName = (evt) => {
        const exchangeName = evt.target.value;
        // Function to filter input results
        setInputValue(exchangeName);
        let filteredExchanges = [];
        var nameLower = exchangeName.toLowerCase();
        filteredExchanges = exchangesAPI.data.filter(
            (exchange) => exchange.name.toLowerCase().includes(nameLower)
        );
        exchangeName.length === 0
            ? setSelectedExchanges([])
            : setSelectedExchanges(filteredExchanges);
    };

    const handleSelectedInput = (selectedCrypto) => {
        // Function to update Chosen crypto state in Create Component
        setSelectedExchanges([]);
        setInputValue(selectedCrypto.id);
    };

    if (exchangesAPI.isSuccess) {
        return (
            <>
                <div className="mb-3">
                    <div>
                        <input
                            placeholder="Search an exchange..."
                            value={inputValue}
                            onChange={(e) => getCryptobyName(e)}
                            name={name}
                            onBlur={onBlur}
                            ref={ref}
                            style={{margin:0}}
                            className={styles.mainInput}
                        />
                    </div>
                    <ul className={darkmode ? styles.listFrameDark : styles.listFrame}>
                        {selectedExchanges.map((selectedCryptoCoin) => (
                            <li
                                className={darkmode ? styles.listItemsDark : styles.listItems}
                                key={selectedCryptoCoin.id}
                                onClick={(evt) => handleSelectedInput(selectedCryptoCoin)}
                            >
                                {selectedCryptoCoin.id}
                            </li>
                        ))}
                    </ul>
                </div>
            </>
        );
    }
};
export default ExchangeSearchInput;
