import axios from "axios";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { API_URL } from "../../settings";
import { simpleSuccessfulMessage } from "../../Helpers/Helpers"

// Function to get main dashboard data 
const fetchDashboard = async () => await axios.get(`${API_URL}/dashboard`).then(response => response.data);
export default function useDashboard() {
  return useQuery("dashboard", fetchDashboard);
}

// Function to get fund detail for dashboard data 
const fetchFundDetail = async fundId => await axios.get(`${API_URL}/dashboard`).then(response => response.data.find(item => item.id === fundId ) );
export function useFundDetail(fundId) {
  return useQuery(["fundDetail", fundId], () => fetchFundDetail(fundId) );
}

// Function to get Fund Holdings for dashboard data 
const fetchFundHoldings = async fundId => await axios.get(`${API_URL}/dashboard/${fundId}/holdings`).then(response => response.data);
export function useFundHoldings(fundId) {
  return useQuery(["fundHoldings", fundId], () => fetchFundHoldings(fundId), { enabled: fundId != null});
}

// Function to get Fund Trades Summary for dashboard data 
const fetchFundTrades = async fundId => await axios.get(`${API_URL}/dashboard/${fundId}/summary/trades`).then(response => response.data);
export function useFundTrades(fundId) {
  return useQuery(["fundTrades", fundId], () => fetchFundTrades(fundId) );
}

// Function to get Fund Staking Summary for dashboard data 
const fetchFundStaking = async fundId => await axios.get(`${API_URL}/dashboard/${fundId}/summary/staking`).then(response => response.data);
export function useFundStaking(fundId) {
  return useQuery(["fundStaking", fundId], () => fetchFundStaking(fundId) );
}

// Function to get Categories by Group for Main Charts Dashboard
const fetchCategoryGroupsDashboard = (fundId, categoryGroup) => axios.get(`${API_URL}/dashboard/${fundId}/categories/${categoryGroup}`).then(response => response.data);
export function useFundCategories(fundId, categoryGroup) {
  return useQuery(["fundCategories", fundId, categoryGroup], () => fetchCategoryGroupsDashboard(fundId, categoryGroup), {enabled: categoryGroup !== undefined} )
}

// SANDBOX
// Function to get Fund Data by its ID to handle in Sandbox
const fetchFundSandboxData = (fundId, queryString) => axios.get(`${API_URL}/sandbox/${fundId}`).then(res => res.data.filter(data => data.cryptoCurrency.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0))
export const useSandboxFundData = (fundId, queryString) => useQuery(["sandboxFund", fundId, queryString], () => fetchFundSandboxData(fundId, queryString))

// Function to get all Sandbox Currencies
const fetchSandboxCurrencies = () => axios.get(`${API_URL}/sandbox/currencies`).then(res => res.data)
export const useSandboxCurrencies = () => useQuery("sandboxCurrencies", fetchSandboxCurrencies, { retry: false })

//MUTATIONS
// Function to add currency to the Sandbox
const addSandboxCurrency = currencyId => axios.put(`${API_URL}/sandbox/currencies`, currencyId).then(res => res.data)
export const useAddSandboxCurrency = () => {
    const queryClient = useQueryClient()
    return useMutation((currencyId) => addSandboxCurrency(currencyId), { onSuccess: async () => {
        queryClient.invalidateQueries("sandboxCurrencies") 
        simpleSuccessfulMessage("Sandbox Currencies have been stored succesfully")
      } 
    })
} 

// STATISTICS AVERAGES
// Function to get average prices by Base Asset and Order Direction
const fetchAveragePrices = (baseAssetId, orderDirection) => axios.get(`${API_URL}/statistics/averageprices/${baseAssetId}/${orderDirection}`).then(res => res.data)
export const useAveragePrices = (baseAssetId, orderDirection) => useQuery(["averagePrices", baseAssetId, orderDirection], () => fetchAveragePrices(baseAssetId, orderDirection), { enabled: baseAssetId !== undefined && orderDirection !== undefined })

