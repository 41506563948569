import React, { useState } from 'react'
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";

const FundHoldingsCardTHeaders = ({ header, sortProp, setSortProp, title}) => {
    const [ sortType, setSortType ] = useState(false)
    
    const onHandleSortType = val => {
        setSortProp({ value: header.value, type: val })
        setSortType(!sortType)
    }

    if (sortProp?.value === header.value && !sortType) {
        return <th title={title} style={{textDecoration:"underline"}} onClick={() => onHandleSortType('desc')}>{ header.name } <FaSortDown style={{fontSize:"18px"}} /></th>
    }

    if (sortProp?.value === header.value && sortType) {
        return <th title={title} style={{ textDecoration:"underline" }} onClick={() => onHandleSortType('asc')}>{ header.name } <FaSortUp style={{fontSize:"18px"}} /></th>
    }

    return (
        <th title={title} onClick={() => setSortProp({ value: header.value, type: 'asc' } )}>
            { header.name} <FaSort style={{fontSize:"18px"}} />
        </th> 
    )
}
export default FundHoldingsCardTHeaders;