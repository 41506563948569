import { Table } from "react-bootstrap";
import styles from "./MarketDepthPage.module.scss";
import React, { useEffect, useState } from "react";
import { useFundHoldings } from "../../query-hooks/dashboard-query-hooks/useDashboard";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import { MarketDepthLine } from "./MarketDepthLine";

const headers = [
  { prop: "symbol", title: "CryptoCurrency" },
  { prop: "usdLiquidityTwoPercentDown", title: "Depth Down -2%" },
  { prop: "usdLiquidityFivePercentDown", title: "Depth Down -5%" },
  { prop: "usdLiquidityTenPercentDown", title: "Depth Down -10%" },
  { prop: "maxAllocation", title: "Max Allocation Based on -2%" },
  { prop: "endUSDValue", title: "Current allocation" },
  { prop: "overUnderAllocationPct", title: "Under/Over allocation %" },
  { prop: "pctHoldingsLiquidatable", title: "% Holdings Liquidatable" },
  { prop: "usdLiquidatable", title: "$ Amount Liquidatable" },
];

export default function MarketDepthTable({ fundId }) {
  const fundHoldings = useFundHoldings(fundId);
  const [sortProp, setSortProp] = useState("symbol");
  const [sortDirection, setSortDirection] = useState("asc");
  const [fundHoldingsCustom, setFundHoldingsCustom] = useState([]);
  const [cryptoGuid, setCryptoGuid] = useState(null);

  useEffect(() => {
    if (!!fundHoldings.data) {
      const updatedHoldings = fundHoldings.data.filter(holding => holding.cryptoCurrency != null).map((holding) => {
        let maxAllocation =
          holding.usdLiquidityTwoPercentDown == null
            ? NaN
            : holding.usdLiquidityTwoPercentDown * 5;
        let overUnderAllocationPct =
          isNaN(maxAllocation) || maxAllocation === 0
            ? NaN
            : (holding.endUSDValue / maxAllocation) * 100;

        let pctHoldingsLiquidatable =
          Math.min(
            holding.usdLiquidityTwoPercentDown / holding.endUSDValue,
            1,
          ) * 100;
        pctHoldingsLiquidatable = isNaN(overUnderAllocationPct)
          ? NaN
          : pctHoldingsLiquidatable;

        let usdLiquidatable = isNaN(pctHoldingsLiquidatable)
          ? NaN
          : (pctHoldingsLiquidatable / 100) * holding.endUSDValue;

        return {
          ...holding,
          maxAllocation,
          overUnderAllocationPct,
          pctHoldingsLiquidatable,
          usdLiquidatable,
        };
      });

      setFundHoldingsCustom(()=>updatedHoldings);
    }
  }, [fundHoldings.data, fundHoldings.isSuccess, fundHoldings.isLoading]);

  useEffect(() => {
    if(fundHoldingsCustom.length === 0){
      return;
    }
    const sortedHoldings = [...fundHoldingsCustom].sort((a, b) => {
      var sa = a[sortProp];
      var sb = b[sortProp];
      if (typeof sa === "string" && typeof sb === "string")
        return sortDirection === "asc"
          ? sa.localeCompare(sb)
          : sb.localeCompare(sa);
      return sortDirection === "asc"
        ? sa - sb || isNaN(sb) - isNaN(sa)
        : sb - sa || isNaN(sa) - isNaN(sb);
    });
    setFundHoldingsCustom(()=>sortedHoldings);
  }, [sortProp, sortDirection]);

  const sortClicked = (newprop) => {
    setSortProp((prev) => {
      if (newprop === prev) {
        let direction = sortDirection === "asc" ? "desc" : "asc";
        setSortDirection(direction);
      } else {
        setSortDirection("asc");
      }
      return newprop;
    });
  };

  function rowClicked(evt){
    if(cryptoGuid === evt){
      setCryptoGuid(null);
      return;
    }
    setCryptoGuid(evt);
  }

  return (
    <>
      <Table responsive borderless={true} className={styles.table}>
        <thead className={`${styles.theader}`}>
          <tr>
            {headers.map((header) => {
              return (
                <th key={header.title} onClick={() => sortClicked(header.prop)}>
                  {header.title}
                  {sortProp === header.prop && sortDirection === "asc" && (
                    <FaSortDown style={{ fontSize: "18px" }} />
                  )}
                  {sortProp === header.prop && sortDirection === "desc" && (
                    <FaSortUp style={{ fontSize: "18px" }} />
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
        {fundHoldingsCustom.map((holding) => (
          <MarketDepthLine
            holding={holding}
            key={holding.id + '-wrapper'}
            cryptoGuid={cryptoGuid}
            clickHandler={rowClicked}
          />
        ))}
      </Table>
    </>
  );
}
