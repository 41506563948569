import { useCurrentMarketDepth } from "../../../query-hooks/market-depth-query-hooks/useMarketDepth";
import useCryptos from "../../../query-hooks/crypto-query-hooks/useCryptos";
import { useEffect, useState } from "react";
import styles from "./MarketDepth.module.scss";
import {
  useAllExchanges,
  useExchangeContracts,
} from "../../../query-hooks/exchange-query-hooks/useExchange";
import _ from "lodash";
import { Line } from "react-chartjs-2";
import { useNetworks } from "../../../query-hooks/wallet-query-hook/useWallet";
import {
  getRandomColor,
  numberEditor,
  toSignificantFigures,
} from "../../../Helpers/Helpers";
import { useAuth } from "../../../Hooks/useAuth";

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  devicePixelRatio: 4,
  layout: { padding: 10 },
  plugins: {
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label;
          if (label) {
            label = `${label}: `;
          }
          if (context.parsed.y !== null) {
            label = `${label} ${numberEditor(context.parsed.y)}`;
          }
          return label;
        },
      },
    },
    legend: {
      position: "bottom",
      align: "center",
      labels: {
        font: { size: 20, family: "LarkenLight" },
        padding: 20,
      },
    },
    datalabels: { display: false },
  },
  scales: {
    y: {
      ticks: {
        callback: function (val) {
          return `${numberEditor(val)}`;
        },
        autoSkip: false,
        font: { family: "LarkenMedium", size: 22 },
        padding: 10,
      },
      grid: { display: true },
      border: { color: "black", width: 3 }, // Axis color and line weight
      offset: true, // Separation between chart line and axis line
    },
    x: {
      ticks: {
        callback: function (val, index, values) {
          const valuesLength = values.length;
          if (valuesLength > 36) {
            if (
              index === 0 ||
              index === valuesLength - 1 ||
              (index % 6 === 0 && index !== valuesLength - 2)
            ) {
              return this.getLabelForValue(index);
            }
            return "";
          }
          if (
            index === 0 ||
            index === valuesLength - 1 ||
            (index % 4 === 0 && index !== valuesLength - 2)
          ) {
            return this.getLabelForValue(index);
          }
          return "";
        },
        autoSkip: false,
        font: { family: "LarkenMedium", size: 22 },
        padding: 10,
        maxRotation: 0,
        minRotation: 0,
      },
      grid: { display: false },
      border: { color: "black", width: 3 }, // Axis color and line weight
      offset: true, // Separation between chart line and axis line
    },
  },
};

const getExchangeContractTypeShortString = (inputString) => {
  switch (inputString) {
    case null:
      return "";
    case "UniswapV2":
      return "V2";
    case "UniswapV3":
      return "V3";
    default:
      return "";
  }
};

/**
 * Make CoinGecko [-2%, +2%] data extend to [-10%...+10%]
 *
 * @param {MarketDepthData} item - market depth data
 * @returns {MarketDepthData} Market depth data with 10% data
 */
function extendTwoPercentToTenPercent(item) {
  item = _.clone(item);
  // remove and re-add 0% to avoid duplicates
  item.marketDepthData = item.marketDepthData.filter((x) => x.percentage !== 0);
  item.marketDepthData.push({ percentage: 0, quoteAmountCumulative: 0 });
  // CoinGecko only returns -2%, 2%, and then there's the 0% from the line above.
  if (item.marketDepthData.length > 3) {
    return item;
  }
  const negative = item.marketDepthData.find(
    (x) => x.percentage === -2,
  ).quoteAmountCumulative;
  const positive = item.marketDepthData.find(
    (x) => x.percentage === 2,
  ).quoteAmountCumulative;

  // returns -10% -> -2%, then -1%, 0, 1%, and lastly 2% -> 10%
  // _.range excludes the end number -1% and 11%
  const marketDepthData = [
    ..._.range(-10, -1).map((x) => ({
      percentage: x,
      quoteAmountCumulative: negative,
    })),
    { percentage: -1, quoteAmountCumulative: negative / 2 },
    { percentage: 0, quoteAmountCumulative: 0 },
    { percentage: 1, quoteAmountCumulative: positive / 2 },
    ..._.range(2, 11).map((x) => ({
      percentage: x,
      quoteAmountCumulative: positive,
    })),
  ];

  return {
    ...item,
    marketDepthData,
  };
}

function extendIdsToObjects(
  depthData,
  exchanges,
  quoteCurrencies,
  exchangeContracts,
  blockchainNetworks,
) {
  // ExchangeName or "Total"  when null
  const exchange =
    exchanges.filter((x) => x.id === depthData.exchangeId)[0]?.exchangeName ??
    "Total";
  const quoteCurrency =
    quoteCurrencies.filter((x) => x.id === depthData.quoteAssetId)[0]?.symbol ??
    "USD";
  const exchangeContract = exchangeContracts.find(
    (x) => x.id === depthData.exchangeContractId,
  );
  const blockchainNetwork = blockchainNetworks.find(
    (x) => x.id === exchangeContract?.blockchainNetworkId,
  );

  return {
    ...depthData,
    exchange: exchange,
    quoteCurrency: quoteCurrency,
    exchangeContractType: getExchangeContractTypeShortString(
      exchangeContract?.orderbookSource,
    ),
    blockchainNetwork: blockchainNetwork,
  };
}

/**
 * Makes sure only the best measurement point for an exchange/quotecurrency pair is put in the chart.
 * @param depthData market depth data
 * @returns {*[]} Filtered market depth data
 */
function getBestDepthSource(depthData) {
  const groupings = _.groupBy(
    depthData,
    (x) => x.exchangeId + x.quoteAssetId + x.exchangeContractId,
  );

  // skip CoinGecko if deep liq data is available
  return Object.keys(groupings).map((key) => {
    groupings[key].sort(
      (x, y) => y.marketDepthData.length - x.marketDepthData.length,
    );
    return extendTwoPercentToTenPercent(groupings[key][0]);
  });
}

/**
 * Transforms MarketDepth[] to {labels: string[], datasets: number[][]}
 * (where datasets is a list of lists for each cryptocurrency and each percentage diff per cryptocurrency)
 * @param marketDepths
 * @param midpoint
 * @param darkmode
 * @returns {{datasets: *, labels: string[]}}
 */
const transformDataForChart = (marketDepths, midpoint, darkmode) => {
  // select [midpoint * 0.9, ..., midpoint * 1.1]
  const labels = Array.from({ length: 21 }, (_, i) => i - 10).map((x) => ({
    pct: x,
    price: toSignificantFigures(midpoint * (1 + x / 100), 3),
  }));

  const totalLineColor = darkmode ? "rgba(255, 255, 255, 1)" : "rgba(0,0,0,1)";
  const datasets = marketDepths.map((item) => {
    // add this so there's a 0% measurement point
    item.marketDepthData.sort((x, y) => x.percentage - y.percentage);
    return {
      data: item.marketDepthData.map((x) => x.quoteAmountCumulative),
      label: `${item.exchange}${item.exchangeContractType ?? ""}${" " + (item.blockchainNetwork?.currencySymbol ?? "")} (${item.quoteCurrency})`,
      borderColor:
        item.exchange === "Total" ? totalLineColor : getRandomColor(),
    };
  });

  // sort by max liquidity at -10%
  datasets.sort((x, y) => y.data[0] - x.data[0]);

  return { labels: labels.map((x) => `$${x.price} (${x.pct}%)`), datasets };
};

const AggregateChart = ({ cryptoGuid, listingPrice }) => {
  let depthDataPromise = useCurrentMarketDepth(cryptoGuid);
  let cryptosPromise = useCryptos();
  let exchangesPromise = useAllExchanges();
  let blockchainNetworksPromise = useNetworks();
  let exchangeContractsPromise = useExchangeContracts();
  const { darkmode } = useAuth();

  let success =
    depthDataPromise.isSuccess &&
    cryptosPromise.isSuccess &&
    exchangesPromise.isSuccess &&
    blockchainNetworksPromise.isSuccess &&
    exchangeContractsPromise.isSuccess;
  let loading =
    depthDataPromise.isLoading ||
    cryptosPromise.isLoading ||
    exchangesPromise.isLoading ||
    blockchainNetworksPromise.isLoading ||
    exchangeContractsPromise.isLoading;
  let error =
    depthDataPromise.isError ||
    cryptosPromise.isError ||
    exchangesPromise.isError ||
    blockchainNetworksPromise.isError ||
    exchangeContractsPromise.isError;

  const [exchanges, setExchanges] = useState([]);
  const [quoteCurrencies, setQuoteCurrencies] = useState([]);
  const [selectedExchange, setSelectedExchange] = useState("all");
  const [selectedQuoteCurrency, setSelectedQuoteCurrency] = useState("all");
  const [depthDataFiltered, setDepthDataFiltered] = useState([]);

  const [chartData, setChartData] = useState(
    /** @type {import("chart-js").ChartData<"line", import("chart-js").DefaultDataPoint<"line">>} */ null,
  );

  const cryptosData = cryptosPromise.data;
  const exchangesData = exchangesPromise.data;

  useEffect(() => {
    if (!success) {
      return;
    }
    setDepthDataFiltered(
      depthDataPromise.data
        ?.filter(
          (x) =>
            x.exchangeId === selectedExchange || selectedExchange === "all",
        )
        ?.filter(
          (x) =>
            x.quoteAssetId === selectedQuoteCurrency ||
            selectedQuoteCurrency === "all",
        ) ?? [],
    );
  }, [
    exchanges,
    success,
    quoteCurrencies,
    selectedExchange,
    selectedQuoteCurrency,
  ]);

  useEffect(() => {
    if (success) {
      setExchanges(
        _.uniq(
          depthDataPromise.data
            .filter((x) => x.exchangeId != null)
            .map((x) =>
              exchangesData.find((exchange) => exchange.id === x.exchangeId),
            ),
        ),
      );
      setQuoteCurrencies(
        _.uniq(
          depthDataPromise.data
            .filter((x) => x.quoteAssetId != null)
            .map((x) =>
              cryptosData.find((crypt) => crypt.id === x.quoteAssetId),
            ),
        ),
      );
    }
  }, [success]);

  useEffect(() => {
    const extended = depthDataFiltered.map((x) =>
      extendIdsToObjects(
        x,
        exchanges,
        quoteCurrencies,
        exchangeContractsPromise.data,
        blockchainNetworksPromise.data.items,
      ),
    );
    let chartData = getBestDepthSource(extended);
    chartData = transformDataForChart(chartData, listingPrice, darkmode);
    setChartData(chartData);
  }, [depthDataFiltered]);

  if (loading) {
    return <h3>loading...</h3>;
  }
  if (error) {
    return <h3>error...</h3>;
  }
  if (success && depthDataPromise.data.length === 0) {
    return <h3>No data available</h3>;
  }
  if (success && depthDataPromise.data.length > 0) {
    return (
      <div style={{ maxWidth: "100%" }}>
        <div>
          <label htmlFor={"quoteCurrency"}>Quote Currency:</label>
          <select
            id={"quoteCurrency"}
            className={styles.selectInput}
            onChange={(evt) => setSelectedQuoteCurrency(evt.target.value)}
          >
            <option key={"all"} value={"all"}>
              All
            </option>
            {quoteCurrencies.map((x) => (
              <option key={x.id} value={x.id}>
                {x.name}
              </option>
            ))}
          </select>
          <label htmlFor={"exchange"}>Exchange:</label>
          <select
            id={"exchange"}
            className={styles.selectInput}
            onChange={(evt) => setSelectedExchange(evt.target.value)}
          >
            <option key={"all"} value={"all"}>
              All
            </option>
            {exchanges.map((x) => (
              <option key={x.id} value={x.id}>
                {x.exchangeName}
              </option>
            ))}
          </select>
        </div>
        {chartData?.labels?.length > 0 && (
          <div style={{ height: "600px", width: "100%" }}>
            <Line options={options} data={chartData} />
          </div>
        )}
      </div>
    );
  }
};

export default AggregateChart;
