// Function to fetch Coinmarketcap API to get crypto data
import {useQuery} from "react-query";
import axios from "axios";
import {API_URL} from "../../settings";

const fetchCoinGeckoCoins = () =>
    axios
        .get("https://api.coingecko.com/api/v3/coins/list", {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: false
        })
        .then((response) => response.data);

export function useCoinGeckoCoins() {
    return useQuery("coinGeckoCoins", fetchCoinGeckoCoins);
}

const fetchExchanges = (source) =>
    axios
        .get( API_URL + "/api/marketAPI/exchanges/" + source, {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: false
        })
        .then((response) => response.data);

export function useRemoteExchanges(source) {
    return useQuery("remoteExchanges." + source, ()=> fetchExchanges(source));
}