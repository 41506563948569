import React, {useEffect} from 'react'
import {useFieldArray, useForm, FormProvider} from "react-hook-form";
import {Form, Row, Col, OverlayTrigger, Tooltip} from "react-bootstrap";
import {Link, useParams} from 'react-router-dom'
import styles from "./ExchangeForm.module.scss";
import ExchangeIconImageHandler from './ExchangeIconImageHandler';
import {useAuth} from '../../Hooks/useAuth';
import {useDexSources, useListingSources} from "../../query-hooks/crypto-query-hooks/useCryptos";
import {
    useImplementedExchangeApis
} from "../../query-hooks/exchange-query-hooks/useExchange";
import {useNetworks} from "../../query-hooks/wallet-query-hook/useWallet";
import {ExchangeContractsForm} from "./ExchangeContracts/ExchangeContractsForm";
import ExchangeSearchInput from "./ExchangeSearchInput";

const ExchangeForm = ({onSubmit, data}) => {
    const {darkmode} = useAuth()
    const {exchangeId} = useParams();
    const listingSources = useListingSources();
    const dexSources = useDexSources();
    const exchangeApis = useImplementedExchangeApis()
    const form = useForm();
    const blockchainNetworks = useNetworks()

    const {control, watch, register, handleSubmit, setValue, reset, formState: {errors}} = form;
    const {fields: sourceIdsFields, append: appendSourceId} = useFieldArray({control, name: 'sourceIdMappings', keyName: 'key'})

    const isDex = watch('isDEX');
    const fetchOrderbook = watch('fetchOrderbook');

    useEffect(() => {
        if (listingSources.isSuccess && dexSources.isSuccess) {
            let existingSources = sourceIdsFields.map(item => item.source);
            // don't show dex sources for the Exchange class, only for the ExchangeContracts
            let sourcesToAdd = listingSources.data.filter(item => !existingSources.includes(item));
            sourcesToAdd.forEach((source) => {
                appendSourceId({source, sourceId: ""});
            })
        }
    }, [listingSources.data, dexSources.data, dexSources.isSuccess, listingSources.isSuccess, appendSourceId, sourceIdsFields]);


    useEffect(() => {
        data && reset({
            id: data.id,
            exchangeName: data.exchangeName,
            url: data.url,
            isDEX: data.isDEX,
            fetchOrderbook: data.fetchOrderbook,
            sourceIdMappings: data.sourceIdMappings,
            orderbookSource: data.orderbookSource,
            exchangeContracts: data.exchangeContracts ?? []
        });
    }, [data, reset]);

    const getImageValue = (updatedImage) => setValue("icon", updatedImage); // Function sent to ImageHandler component to update SetValue state

    return (
        <>
            <FormProvider {...form}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="justify-content-center">
                        <Col lg={6} md={12} className="py-1">
                            <ExchangeIconImageHandler getImageValue={getImageValue} exchangeId={exchangeId}/>
                            <input {...register("icon")} type="hidden"/>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={6} md={12} className="py-1">
                            <h6 className={darkmode ? styles.inputLabelDark : styles.inputLabel}>Enter Exchange
                                Name</h6>
                            <input className={styles.fundFormInputs}
                                   placeholder="Exchange Name" {...register("exchangeName", {
                                required: true,
                                maxlength: 60
                            })} />
                            {errors.exchangeName && errors.exchangeName.type === "required" && (
                                <span className={styles.formErrorNotice}>This field is required</span>)}
                            {errors.exchangeName && errors.exchangeName.type === "maxLength" && (
                                <span className={styles.formErrorNotice}>Max length of 60 characters exceeded</span>)}
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={6} md={12} className="py-3">
                            <h6 className={darkmode ? styles.inputLabelDark : styles.inputLabel}>Enter Url</h6>
                            <input className={styles.fundFormInputs} placeholder="URL" {...register("url", {
                                required: true,
                                maxLength: 256
                            })} />
                            {errors.url && errors.url.type === "required" && (
                                <span className={styles.formErrorNotice}>This field is required</span>)}
                            {errors.url && errors.url.type === "maxLength" && (
                                <span className={styles.formErrorNotice}>Max length of 256 characters exceeded</span>)}
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <div style={{marginTop: "1rem", display: "flex", justifyContent: 'center'}}>
                            <label className={styles.checkbox}>
                                <input type="checkbox" {...register("isDEX")} />
                                <span>DEX</span>
                            </label>
                            <OverlayTrigger overlay={
                                <Tooltip id="orderbookTooltip">
                                    Not every exchange supports deep order books. Sometimes it's better to fetch the
                                    ±2% from CoinGecko.
                                </Tooltip>
                            }>
                                <label className={styles.checkbox} style={{marginBottom: 0}}>
                                    <input type="checkbox" {...register("fetchOrderbook")} />
                                    <span>Fetch orderbook</span>
                                </label>
                            </OverlayTrigger>
                        </div>
                    </Row>
                    {exchangeApis.isSuccess && fetchOrderbook && !isDex &&
                        (<Row className="mb-5">
                            <div style={{display: "flex", justifyContent: 'center'}}>
                                <label className={darkmode ? styles.inputLabel : styles.inputLabelDark}>
                                    <span>Exchange type</span>
                                    <select
                                        className={darkmode ? styles.selectInputDark : styles.selectInput}
                                        {...register("orderbookSource")}>
                                        <option value="None">Aggregator</option>
                                        {
                                            exchangeApis.data.includes(exchangeId) &&
                                            <option value="CentralizedExchange">Fetch from Exchange</option>
                                        }
                                        {
                                            // no dex options for exchange object, only for ExchangeContract
                                        }
                                    </select>
                                </label>
                            </div>
                        </Row>)
                    }
                    {!isDex && fetchOrderbook && (
                        <Row className="mt-2 justify-content-center">
                            <Col lg={4} md={12}>
                                <div style={{marginTop: "2rem"}}>
                                    <p className={darkmode ? styles.inputLabelDark : styles.inputLabel}>
                                        Find the id by searching the id list on
                                        <br/>
                                        <tt>https://api.coingecko.com/api/v3/exchanges</tt>
                                        <br/>
                                        or by fetching the exchange slug from the url for
                                        CoinMarketCap <tt>coinmarketcap.com/exchanges/<b>pancakeswap-v2</b></tt>
                                    </p>
                                </div>
                            </Col>
                            <Col lg={1}></Col>
                            <Col lg={4} md={12}>
                                <div style={{marginTop: "1rem", display: "flex"}}>
                                    <table className="table" style={{width: '100%'}}>
                                        <thead>
                                        <tr>
                                            <th>Source</th>
                                            <th>Id on source</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {sourceIdsFields.map((field, index) =>
                                            <tr key={`row-${field.key}`}>
                                                <td style={{alignContent: 'center'}}>{field.source}</td>
                                                <td style={{alignContent: 'center'}}>
                                                    <ExchangeSearchInput
                                                        key={field.key}
                                                        source={field.source}
                                                        reg={{...register(`sourceIdMappings.${index}.sourceId`)}}
                                                        value={field.sourceId}
                                                        className={styles.fundFormInputs}/>
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                        </Row>
                    )}
                    {
                        (listingSources.isSuccess && dexSources.isSuccess && blockchainNetworks.isSuccess) && (
                            <ExchangeContractsForm></ExchangeContractsForm>
                        )
                    }
                    <Row className="d-flex justify-content-center">
                        <Col lg={4} md={12}>
                            <input className={styles.submitButton} type="submit"/>
                        </Col>
                    </Row>
                    <Row className="d-flex text-center mt-2 mb-5">
                        <Link className={styles.backLink} to="/main/exchanges">Back</Link>
                    </Row>
                </Form>
            </FormProvider>
        </>
    )
}
export default ExchangeForm