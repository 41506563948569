import {useMarketDepthHistory} from "../../../query-hooks/market-depth-query-hooks/useMarketDepth";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {useEffect, useState} from "react";
import {useAuth} from "../../../Hooks/useAuth";
import styles from "./MarketDepth.module.scss";
import {numberEditor} from "../../../Helpers/Helpers";

const DateFormatter = date => {
  var d = new Date(date);
  return d.getFullYear() + "-"
    + ((d.getMonth() + 1).toString().length === 1 ? '0' : '') + (d.getMonth() + 1) + "-"
    + (d.getDate().toString().length === 1 ? '0' : '') + d.getDate();
};


const CustomTooltip = ({active, payload, label, darkmode}) => {
  if (active && payload && payload.length) {
    return (
      <div>
        <p style={{color: darkmode ? 'white' : 'black'}}
           className="label">{`${DateFormatter(label)}: ${numberEditor(payload[0].payload.y)}`}</p>
      </div>
    );
  }

  return null;
};


export default function HistoryChart({id}) {
  const {darkmode} = useAuth();
  const [data, setData] = useState([]);
  const [filter, updateFilter] = useState(-2);
  const [timeframe, setTimeframe] = useState(null);
  const history = useMarketDepthHistory(id, timeframe);

  useEffect(() => {
    if (history.isSuccess) {
      let toSet = [];
      for (let item of history.data.history) {
        toSet.push({
          x: new Date(item.timeStamp).getTime(),
          y: item.marketDepths.filter(x => x.pct === filter)[0].usd
        });
      }
      setData(toSet);
    }
  }, [history.data, filter])

  var filterRows = [];
  for (var i = -10; i <= 10; i++) {
    if (i !== 0) {
      filterRows.push(<option key={i} value={i}>{i}%</option>);
    }
  }

  if(data.length === 0){
    return <h3>No data available</h3>
  }

  function setStartTime(timeframe){
    var start = new Date();
    switch(timeframe){
      case "All":
        start = null;
        break;
      case "1Y":
        start.setTime(start.getTime() - 365 * 24 * 3600 * 1000);
        break;
      case "6m":
        start.setTime(start.getTime() - 0.5 * 365 * 24 * 3600 * 1000);
        break;
      case "3m":
        start.setTime(start.getTime() - 0.25 * 365 * 24 * 3600 * 1000);
        break;
      case "1m":
        start.setTime(start.getTime() - 30 * 24 * 3600 * 1000);
        break;
    }
    setTimeframe(start);
  }

  if (data.length > 0) {
    return (
      <>
        <table>
          <tbody>
            <tr>
              <td>
                <label className={["h-full", "text-center"]}>Percentage</label>
              </td>
            </tr>
            <tr>
              <td>
                <select
                  className={
                    darkmode ? styles.selectInputDark : styles.selectInput
                  }
                  onChange={(evt) => updateFilter(Number(evt.target.value))}
                  value={filter}
                >
                  {filterRows}
                </select>
              </td>
              <td>
                <button
                  className={styles.submitButton}
                  type={"button"}
                  onClick={() => setStartTime("1Y")}
                >
                  1Y
                </button>
              </td>
              <td>
                <button
                  className={styles.submitButton}
                  type={"button"}
                  onClick={() => setStartTime("6m")}
                >
                  6m
                </button>
              </td>
              <td>
                <button
                  className={styles.submitButton}
                  type={"button"}
                  onClick={() => setStartTime("3m")}
                >
                  3m
                </button>
              </td>
              <td>
                <button
                  className={styles.submitButton}
                  type={"button"}
                  onClick={() => setStartTime("1m")}
                >
                  1m
                </button>
              </td>
              <td>
                <button
                  className={styles.submitButton}
                  type={"button"}
                  onClick={() => setStartTime("All")}
                >
                  All
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        {data.length > 0 && (
          <div style={{ marginTop: "4em", width: "100%", height: 400 }}>
            <ResponsiveContainer width="95%" heigh={400}>
              <LineChart data={data}>
                <Line
                  type="monotone"
                  dataKey="y"
                  stroke={darkmode ? "white" : "black"}
                  strokeWidth={2}
                  dot={false}
                />
                <CartesianGrid stroke="#ccc" />
                <XAxis
                  dataKey={"x"}
                  domain={[data[0].x, data[data.length - 1].x]}
                  scale="time"
                  type="number"
                  tickFormatter={DateFormatter}
                />
                <XAxis />
                <YAxis
                  domain={[0, (datamax) => datamax * 1.5]}
                  tickFormatter={numberEditor}
                  dataKey={"y"}
                ></YAxis>
                <Tooltip
                  content={<CustomTooltip darkmode={darkmode} />}
                ></Tooltip>
              </LineChart>
            </ResponsiveContainer>
          </div>
        )}
      </>
    );
  }
}