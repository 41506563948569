import useFunds from "../../query-hooks/fund-query-hooks/useFund";
import { useState } from "react";
import styles from "./MarketDepthPage.module.scss";
import { useAuth } from "../../Hooks/useAuth";
import { Col, Row } from "react-bootstrap";
import { Spinner } from "../../Helpers/Helpers";
import MarketDepthTable from "./MarketDepthTable";
import {MarketDepthStatusWidget} from "./MarketDepthStatusWidget";

export default function MarketDepthPage() {
  const { darkmode } = useAuth();
  const funds = useFunds();
  const [fundId, setFundId] = useState("b4878c95-1493-4f4e-a5a1-fc6fb288e369");

  if (funds.isLoading) {
    return <Spinner />;
  }
  if (funds.isSuccess) {
    return (
      <>
        <select
          className={darkmode ? styles.selectInputDark : styles.selectInput}
          placeholder={"Select Fund"}
          value={fundId}
          onChange={(evt) => setFundId(evt.target.value)}
        >
          {funds.data.items.map((fund:any) => (
            <option key={fund.id} value={fund.id}>
              {fund.fundName}
            </option>
          ))}
        </select>

        <MarketDepthStatusWidget></MarketDepthStatusWidget>

        <Row className={"mt-4"}>
          <Col sm={12}>
            <MarketDepthTable fundId={fundId} />
          </Col>
        </Row>
      </>
    );
  }
}
