import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFundDetail, useFundHoldings, useFundTrades, useFundStaking } from "../../query-hooks/dashboard-query-hooks/useDashboard";
import { Spinner } from "../../Helpers/Helpers";
import NavCharts from "./Charts/NavCharts";
import LayersChart from "./Charts/LayersChart";
import TreeMapChart from "./Charts/TreeMapChart"
import { Row, Col } from "react-bootstrap"
import styles from "./DashboardFundDetail.module.scss"
import DashboardFundHoldings from './Tables/DashboardFundHoldings';
import DashboardFundTrades from './Tables/DashboardFundTrades';
import DashboardFundStaking from './Tables/DashboardFundStaking';
import { useAuth } from '../../Hooks/useAuth';
import { numberEditor, bookingPerioddateFormat } from '../../Helpers/Helpers';

const DashboardFundDetail = () => {
    const { fundId } = useParams()
    const fundDetail = useFundDetail(fundId)
    const fundHoldings =  useFundHoldings(fundId)
    const fundTrades = useFundTrades(fundId)
    const fundStaking = useFundStaking(fundId)
    const [ table, setTable ] = useState("0")
    const { darkmode } = useAuth()
    
    return (
        <>
            { fundDetail.isSuccess && 
                <>
                    <Row>
                        <Col xl={4}>
                            <div className={darkmode ? styles.cardTableDark : styles.cardTable}>
                                <div>
                                    <h5 className={styles.mainTitle}>{fundDetail.data.fundName}</h5>
                                    <h5 className={styles.currentBookingPeriod}>Current Booking Period: {bookingPerioddateFormat(fundDetail.data.currentBookingPeriod)}</h5>
                                </div>
                                <div className={darkmode ? styles.reportsDark : styles.reports}>
                                    <div className={styles.headerData}><h5 className={styles.headerTitle}>HWM</h5><h4>{ numberEditor(fundDetail.data.shareValueHWM) }</h4></div>
                                    <div className={styles.headerData}><h5 className={styles.headerTitle}>DAILY NAV</h5><h4>{ fundDetail.data.dailyNavs.length > 0 ? numberEditor(fundDetail.data.dailyNavs[fundDetail.data.dailyNavs.length - 1].nav) : "---" }</h4></div>
                                    <div className={styles.headerData}><h5 className={styles.headerTitle}>PREV NAV</h5><h4>{ fundDetail.data.nav ? numberEditor(fundDetail.data.nav.nav) : "---" }</h4></div>
                                </div>                
                                <div >
                                    <NavCharts dailyNavs={[...fundDetail.data.dailyNavs].reverse()} />
                                </div>
                                <div >
                                    <LayersChart layers={fundDetail.data.layers} />
                                </div>
                                <div >
                                    <TreeMapChart holdings={fundDetail.data.holdings}  /> 
                                </div>                   
                            </div>
                        </Col>
                        <Col xl={8} md={12} xs={12} className="text-center" >
                            <div className={darkmode ? styles.mainChartsCardDark : styles.mainChartsCard}>
                                <button className={styles.tableButton} value={0} onClick={e => setTable(e.target.value)}>Coins</button>
                                <button className={styles.tableButton} value={1} onClick={e => setTable(e.target.value)}>Trades</button>
                                <button className={styles.tableButton} value={2} onClick={e => setTable(e.target.value)}>Staking</button>
                                { table === "0" && ( fundHoldings.isSuccess && <DashboardFundHoldings holdings={fundHoldings.data} /> )}
                                { table === "1" && ( fundTrades.isSuccess && <DashboardFundTrades trades={fundTrades.data} /> )}
                                { table === "2" && (fundStaking.isSuccess && <DashboardFundStaking staking={fundStaking.data} /> )} 
                            </div>
                        </Col>
                    </Row>
                </>
            }
            { fundDetail.isLoading && <Spinner /> }
        </>
    )
}
export default DashboardFundDetail