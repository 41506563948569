import React, { useState } from 'react'
import { Row, Col, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styles from "../FundDetail.module.scss"
import Pagination from '../../../Components/Pagination'
import { useAuth } from '../../../Hooks/useAuth'
import { ErrorLoading, Spinner } from '../../../Helpers/Helpers'
import EmptyMessageComponent from "../../../Components/EmptyMessageComponent"
import { AiOutlineDelete } from "react-icons/ai";
import CollapsibleComponent from '../../../Components/CollapsibleComponent'
import {
  confirmationAlertDeleteExchangeAccount,
  useDeleteExchangeAccount
} from "../../../query-hooks/exchange-query-hooks/useExchange";

const FundExchangeAccountDashboardCard = ({fund, fundId} ) => {
    const { darkmode } = useAuth();
    const mutation = useDeleteExchangeAccount();
    const [currentItems, setCurrentItems] = useState();
    const getCurrentItems = (updatedItems) => setCurrentItems(updatedItems)

    if (fund.isSuccess && fund.data.exchangeAccounts.length < 1 ) {
        return (
            <CollapsibleComponent title="Exchange Accounts">
                <Row className="mb-4">
                    <Col lg={12} className={styles.addContainer}>Add an Exchange Account <Link className={styles.addIcon} to={`/main/funds/create-fund-exchange-account/${fundId}`}>+</Link></Col>
                </Row>
                <EmptyMessageComponent message="No Exchange Accounts Created" />
            </CollapsibleComponent>
        )
    }
    
    return (
        <CollapsibleComponent title="Exchange Accounts">
            <Row className="mb-4">
                <Col lg={12} className={styles.addContainer}>Add an Exchange Account <Link className={styles.addIcon} to={`/main/funds/create-fund-exchange-account/${fundId}`}>+</Link></Col>
            </Row>
            <Table responsive borderless={true}>
                <thead>
                    <tr className={styles.theader} >
                        <th></th> 
                        <th>Exchange</th>
                    </tr>
                </thead>
                { currentItems && currentItems.map((exchangeAccount) => (
                    <tbody key={exchangeAccount.id}>
                        <tr className={ darkmode ? styles.tableStripsDark : styles.tableStrips } >
                            <td style={{textAlign:"left"}}>{ exchangeAccount.name }</td>
                            <td>{ exchangeAccount.exchange.exchangeName}</td>
                            <td><Link className={darkmode ? styles.editButtonDark : styles.editButton } to={`/main/funds/edit-fund-exchange-account/${fundId}/${exchangeAccount.id}`}>Edit</Link> </td>
                            <td className={styles.tooltip} onClick={e => confirmationAlertDeleteExchangeAccount(e, exchangeAccount.id, mutation)}>
                                <AiOutlineDelete className={styles.icons} />
                                <span className={styles.tooltiptext}>Delete</span>
                            </td>
                        </tr>
                    </tbody>
                )) }
            </Table>
            { fund.isSuccess && <Pagination items={fund.data.exchangeAccounts} getCurrentItems={getCurrentItems} itemsPerPage={8} /> }
            { fund.isLoading && <Spinner /> }  
            { fund.isError && <ErrorLoading error={fund.error} /> }
        </CollapsibleComponent>
    )
}
export default FundExchangeAccountDashboardCard