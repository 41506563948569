import axios from "axios";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { API_URL } from "../../settings"
import Swal from "sweetalert2";

// Function to get all cryptos
const fetchCryptos = () => axios.get(`${API_URL}/cryptos/list`).then(response => response.data);
export default function useCryptos() {
  return useQuery(["cryptos"], () => fetchCryptos());
}

// Function to get crypto using a search query
const fetchCryptoSearch = (queryString, page) => axios.get(`${API_URL}/cryptos`, {params:{ search: queryString, page: page}}).then(res => res.data);
export function useSearchCryptos(queryString, page) {
  return useQuery(["searchCrypto", queryString, page], () => fetchCryptoSearch(queryString, page), { enabled: queryString !== undefined && page !== undefined });
}

// Function to get detail of one crypto
const fetchCrypto = (cryptoGuid) => axios.get(`${API_URL}/cryptos/${cryptoGuid}`).then((response) => response.data);
export function useCrypto(cryptoGuid) {
  return useQuery(["crypto", cryptoGuid], () => fetchCrypto(cryptoGuid), {enabled: cryptoGuid !== undefined});
}

// Function to get all crypto listings
const fetchAllCryptoListings = (cryptoGuid, fromDate, toDate, page) => axios.get(`${API_URL}/cryptos/${cryptoGuid}/listings`, {params: {from: fromDate, to: toDate, page: page}}).then(res => res.data); 
export function useAllCryptoListings(cryptoGuid, fromDate, toDate, page) {
  return useQuery(["AllCryptoListings", cryptoGuid, fromDate, toDate, page], () => fetchAllCryptoListings(cryptoGuid, fromDate, toDate, page), {enabled: cryptoGuid !== undefined});
}

// Function to get one crypto listings
const fetchCryptoListings = (cryptoGuid, listingId) =>
  axios.get(`${API_URL}/cryptos/${cryptoGuid}/listings/${listingId}`).then(response => response.data);

export function useCryptoListing(cryptoGuid, listingId) {
  return useQuery(["cryptoListing", cryptoGuid, listingId], () => fetchCryptoListings(cryptoGuid, listingId), { enabled: listingId !== undefined }  );
}


// Function to get Crypto Logo Image
const fetchCryptoLogo = (symbol) => axios.get(`${API_URL}/cryptos/icons/${symbol}`).then(response => response.data);
export function useCryptoLogo(symbol) {
  return useQuery(["cryptoLogo", symbol], () => fetchCryptoLogo(symbol));
}

//Function to fetch Bitcoin data for Fund Form default Value 
const fetchBitcoin = async () => await axios.get(`${API_URL}/cryptos?search=Bitcoin`).then(response => response.data.items.find(crypto => crypto.name === "Bitcoin"));
export function useBitcoin() {
  return useQuery("bitcoin", fetchBitcoin);
}

//Function to get Listing Sources 
const listingSources = async () => await axios.get(`${API_URL}/cryptos/listingsources`).then(res => res.data);
/**
 *
 * @returns {import('react-query').UseQueryResult<string[], unknown>}
 */
export const useListingSources = () => useQuery("listingSources", listingSources);

//Function to get Dex Sources
const dexSources = async () => await axios.get(`${API_URL}/cryptos/dexSources`).then(res => res.data);

/**
 * Gets sources that the backend needs external ids to be mapped for
 * @returns {import('react-query').UseQueryResult<string[], unknown>}
 */
export const useDexSources = () => useQuery("dexsources", dexSources);

// Get Listings Booking Periods list to Import from Excel NAV
const getBookingPeriods = () => axios.get(`${API_URL}/listings/bookingperiods`).then(res => res.data.reverse()) 
export const useGetBookingPeriods = () => useQuery("bookingPeriods", getBookingPeriods)

//MUTATIONS

//Function to post Crypto
const postCrypto = (crypto) => axios.post(`${API_URL}/cryptos`, crypto).then(response => response.data);

export function useCreateCrypto() {
  const queryClient = useQueryClient()
  return useMutation(crypto => postCrypto(crypto),
    { onSuccess: async () => queryClient.invalidateQueries("cryptos"), onError: async (error) => Swal.fire(error.response.data.errors[0].description)  }
  );
}

//Function to edit Crypto
const editCrypto = (crypto, cryptoId) => axios.put(`${API_URL}/cryptos/${cryptoId}`, crypto).then(response => response.data);

export function useEditCrypto() {
  const queryClient = useQueryClient()
  return useMutation(({ crypto, cryptoId }) => editCrypto(crypto, cryptoId),
    { onSuccess: async () => queryClient.invalidateQueries("cryptos"), onError: async (error) => Swal.fire(error.response.data.errors[0].description)  }
  );
}

//Function to delete Crypto
const deleteCrypto = (id) => axios.delete(`${API_URL}/cryptos/${id}`).then(response => response.data);
export function useDeleteCrypto() {
  const queryClient = useQueryClient()
  return useMutation(({ id }) => deleteCrypto(id), 
    { onSuccess: async () => queryClient.invalidateQueries("cryptos"), onError: async (error) => Swal.fire(error.response.data.errors[0].description)  }
  );
}
// Message object inserted in delete message
const deleteMessageObject = {
  title: "Are you sure you want to delete this Crypto?", 
  text: "You won't be able to revert this!", 
  icon: "warning", 
  showCancelButton: true, 
  confirmButtonColor: "#6d9e93",
  cancelButtonColor: "#d33", 
  confirmButtonText: "Yes, delete it!"
}

export const confirmationAlertDeleteCrypto = (event, identifier, mutation) => {
  event.preventDefault();
  Swal.fire(deleteMessageObject).then((result) => result.isConfirmed && mutation.mutate({ id: identifier }) );
};

// Get UTC TimeStamp function
export const getUTCTime = () => {
  const now = new Date();
  return now.toISOString();
};

// LISTINGS MUTATIONS

//Function to post Crypto Listing
const postCryptoListing = (cryptoListing, cryptoId) => {
  axios.post(`${API_URL}/cryptos/${cryptoId}/listings`, cryptoListing).then(response => response.data);
} 

export function useCreateCryptoListing() {
  const queryClient = useQueryClient()
  return useMutation( ({cryptoListing, cryptoId }) => postCryptoListing(cryptoListing, cryptoId),
  {
    onSuccess: () => {
      queryClient.invalidateQueries("AllCryptoListings")
    }
  }
  );
}


//Function to edit Crypto Listing
const editCryptoListing = (cryptoListing, cryptoId, listingId) => {
  axios.put(`${API_URL}/cryptos/${cryptoId}/listings/${listingId}`, cryptoListing).then(response => response.data);
} 

export function useEditCryptoListing() {
  const queryClient = useQueryClient()
  return useMutation(({ cryptoListing, cryptoId, listingId }) => editCryptoListing(cryptoListing, cryptoId, listingId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("AllCryptoListings")
      }
    }
  );
}


//Function to delete a listing
const deleteListing = (id_crypto, id_listing) => {
  axios
    .delete(`${API_URL}/cryptos/${id_crypto}/listings/${id_listing}?listingId=${id_listing}`)
    .then(
      Swal.fire({
        title: "The Listing has been deleted succesfully",
        confirmButtonText: "Ok",
        confirmButtonColor: "#6d9e93",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      })
    )
    .catch((error) => console.log(error));
};

// Confirmation alert that shows delete confirmation
export const confirmationAlertDeleteListing = (event, cryptoId, listingId) => {
  // event caught to avoid page refreshing with different url
  event.preventDefault();
  console.log(cryptoId, listingId)
  Swal.fire({
    title: "Are you sure you want to delete this Listing?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#6d9e93",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      deleteListing(cryptoId, listingId);
    }
  });
};

// Function to import Listings from Excel Sheets
const importListings = async (listings, bookingPeriod) => axios.post(`${API_URL}/listings/${bookingPeriod}`, listings, { headers: {'Content-Type': 'application/json'}}).then(res => res.data)
export const useImportListings = () => {
  const queryClient = useQueryClient()
  return useMutation( ({listings, bookingPeriod}) => importListings(listings, bookingPeriod), { onSuccess: () => queryClient.invalidateQueries("cryptos")});
}

// Function to import Listings Screenshots
const importScreenshots = async (listings, bookingPeriod) => {
  let formData = new FormData()
  formData.append("endDate", listings.endDate)
  formData.append("fiatCurrency", listings.fiatCurrency)
  formData.append("files", listings.files)
  return axios.post(`${API_URL}/listings/${bookingPeriod}/images`, formData).then(res => res.data)
};

export const useImportScreenshots = () => {
  const queryClient = useQueryClient()
  return useMutation( ({listings, bookingPeriod}) => importScreenshots(listings, bookingPeriod), 
  {   onSuccess: () => {
        queryClient.invalidateQueries("cryptos")
      }
  })
}



