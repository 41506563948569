import React, { useState, useEffect } from "react";
import Logo from "../../images/Hodl-logo.jpg"
import styles from "./ExchangeForm.module.scss";
import { API_URL} from "../../settings";
import { useAuth } from "../../Hooks/useAuth";

const ExchangeIconImageHandler = ({ getImageValue, exchangeId }) => {
    const [ imageIcon, setImageIcon ] = useState(Logo)
    useEffect(() => { exchangeId && setImageUrl(exchangeId) }, [exchangeId]);
    const { darkmode } = useAuth()

    // Function to convert file from input into Base64
    function readURL(input) {
        if (input.files && input.files[0]) {
        const reader = new FileReader();

        reader.onloadend = function (e) {
            getImageValue(e.target.result)
            setImageIcon(e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
        }
    }

    // Function to edit Exchange
    function setImageUrl(exchangeId) {
        fetch(`${API_URL}/exchanges/${exchangeId}/icon`) // Get the remote image as a Blob with the fetch API
            .then(res => res.status === 200 ? res.blob() : false)
            .then((blob) => {  // Read the Blob as DataURL using the FileReader API
                const reader = new FileReader();
                reader.onloadend = () => {
                    //console.log(reader.result);
                    // Logs data:image/jpeg;base64,wL2dvYWwgbW9yZ...
                    getImageValue(reader.result)
                    setImageIcon(reader.result);
                };
                if(!!blob)
                    reader.readAsDataURL(blob);
            }
        );
    }

    // Handle img src error
    const handleImageError = (e) => setImageIcon(Logo)

    return (
        <label className={styles.fileInput}>
            <input type="file" accept="image/*" id="fileupload" onChange={(e) => readURL(e.target)} />
            <div className={styles.cryptoLogo}>
                <img key={Math.random()} src={ imageIcon } onError={ handleImageError } width="120px" alt="Logo" />            
            </div>
            <h6 className={ `${darkmode ? styles.inputLabelDark : styles.inputLabel} text-center` }>{ exchangeId ? "Edit Icon" : "Add an Icon" }</h6>
        </label>
    )
};
export default ExchangeIconImageHandler;
