import React, {useEffect} from "react";
import {Col, Collapse, Row} from "react-bootstrap";
import {useFieldArray, useFormContext} from "react-hook-form";
import {
  confirmationAlertDeleteExchangeContract,
  useDeleteExchangeContract,
} from "../../../query-hooks/exchange-query-hooks/useExchange";
import {useAuth} from "../../../Hooks/useAuth";
import styles from "./ExchangeContracts.module.scss";
import {useNetworks} from "../../../query-hooks/wallet-query-hook/useWallet";
import {ExchangeContractChildren} from "./ExchangeContractChildren";

/**
 * @returns {JSX.Element}
 */
export function ExchangeContractsForm() {
  const {darkmode} = useAuth();
  // Form context is injected from the parent. It is a mapping from Exchange -> Exchange Contracts -> Contract source mappings.
  // It is only visible when the exchange is a DEX.
  const {register, watch, control} = useFormContext();
  const {
    fields: exchangeContractFields,
    append: appendExchangeContract,
    remove: removeExchangeContract,
  } = useFieldArray({
    control,
    name: "exchangeContracts",
    keyName: "key",
  });

  const blockchainNetworks = useNetworks();
  const deleteExchangeContractMutation = useDeleteExchangeContract();

  const [showSourcesCollapse, setShowSourcesCollapse] = React.useState(false);
  const [blockchainToSelect, setBlockchainToSelect] = React.useState(null);

  const isDex = watch("isDEX");

  useEffect(() => {
    if (blockchainNetworks.isSuccess) {
      setBlockchainToSelect(blockchainNetworks.data.items[0].id);
    }
  }, [blockchainNetworks.data]);

  const selectBlockchain = (e) => setBlockchainToSelect(e.target.value);
  const addExchangeContract = (_evt) =>
    appendExchangeContract({
      blockchainNetworkId: blockchainToSelect,
      contractAddress: "",
      sourceIdMappings: [],
    });

  const deleteExchangeContract = async (evt, index) => {
    const contract = exchangeContractFields[index];
    if (!contract.id) {
      removeExchangeContract(index);
      return;
    }

    await confirmationAlertDeleteExchangeContract(
      evt,
      contract.id,
      deleteExchangeContractMutation,
    );
    removeExchangeContract(index);
  };

  return (
    isDex && (
      <>
        <Row className="justify-content-center">
          <Col md={6} className="justify-content-center">
            <h5 className="text-center">
              <a
                className={darkmode ? styles.subTitleDark : styles.subTitle}
                onClick={() => setShowSourcesCollapse(!showSourcesCollapse)}
              >
                <u>
                  {isDex ? "Edit contracts" : "Edit source ids"}{" "}
                  {showSourcesCollapse ? "↑" : "↓"}
                </u>
              </a>
            </h5>
          </Col>
        </Row>

        <Collapse in={showSourcesCollapse}>
          <div>
            {exchangeContractFields
              .filter((_x) => isDex)
              .map((field, index) => (
                <Row
                  className="mt-2 justify-content-center"
                  key={"exchangeContract" + index}
                >
                  <Col
                    lg={4}
                    md={0}
                    style={{
                      borderTop: "1px solid white",
                      paddingTop: "1rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <select
                      className={
                        darkmode ? styles.selectInputDark : styles.selectInput
                      }
                      style={{width: "70%"}}
                      {...register(
                        `exchangeContracts.${index}.blockchainNetworkId`,
                      )}
                    >
                      {blockchainNetworks.data.items.map((network) => (
                        <option key={network.id} value={network.id}>
                          {network.name}
                        </option>
                      ))}
                    </select>
                    <button
                      style={{float: "right", margin: 0}}
                      className={styles.deleteButton}
                      onClick={(evt) => deleteExchangeContract(evt, index)}
                    >
                      Delete
                    </button>
                    <br/>
                    <br/>
                    <input
                      className={styles.fundFormInputs}
                      placeholder={"Contract address"}
                      {...register(
                        `exchangeContracts.${index}.contractAddress`,
                      )}
                    />
                    <br/>
                    <div>
                      <label
                        className={`${darkmode ? styles.inputLabel : styles.inputLabelDark} w-100`}
                      >
                        <span>Exchange type</span>
                        <select
                          className={
                            darkmode
                              ? styles.selectInputDark
                              : styles.selectInput
                          }
                          {...register(
                            `exchangeContracts.${index}.orderbookSource`,
                          )}
                        >
                          <option value="None">CoinGecko</option>
                          {
                            // no option for centralised exchange
                          }
                          <option value="UniswapV2">UniSwap V2</option>
                          <option value="UniswapV3">UniSwap V3</option>
                        </select>
                      </label>
                    </div>
                  </Col>
                  <Col
                    lg={1}
                    md={0}
                    style={{borderTop: "1px solid white"}}
                  ></Col>
                  <Col
                    lg={4}
                    md={12}
                    style={{borderTop: "1px solid white", paddingTop: "1rem"}}
                  >
                    <ExchangeContractChildren
                      fieldIndex={index}
                      key={field.id}
                    />
                  </Col>
                </Row>
              ))}
            {isDex && (
              <Row className="mt-2 mb-2 justify-content-center">
                <Col xs={4}>
                  <span>Chain:</span>
                </Col>
                <Col xs={4}>
                  {
                    <select
                      className={
                        darkmode ? styles.selectInputDark : styles.selectInput
                      }
                      onChange={selectBlockchain}
                    >
                      {blockchainNetworks.data.items.map((network) => (
                        <option key={network.id} value={network.id}>
                          {network.name}
                        </option>
                      ))}
                    </select>
                  }
                </Col>
                <Col xs={4}>
                  <button
                    className={styles.submitButton}
                    type="button"
                    onClick={addExchangeContract}
                  >
                    Add Exchange Contract
                  </button>
                </Col>
              </Row>
            )}
          </div>
        </Collapse>
      </>
    )
  );
}
