import {useFieldArray, useFormContext} from "react-hook-form";
import {useDexSources, useListingSources} from "../../../query-hooks/crypto-query-hooks/useCryptos";
import React, {useEffect, useRef, useState} from "react";
import styles from "../ExchangeForm.module.scss";
import ExchangeSearchInput from "../ExchangeSearchInput";

export const ExchangeContractChildren = ({fieldIndex}) => {
    const {register } = useFormContext();
    const {
        fields: contractSources,
        append: appendSource,
    } = useFieldArray({
        name: `exchangeContracts.${fieldIndex}.sourceIdMappings`,
        keyName:'key'
    });

    const listingSources = useListingSources();
    const dexSources = useDexSources();
    const [allSources, setAllSources] = useState([]);
    useEffect(()=>{
        if(listingSources.isSuccess && dexSources.isSuccess){
            setAllSources([...listingSources.data, ...dexSources.data]);
        }
    }, [listingSources.isLoading, dexSources.isLoading]);

    // needed for strict mode
    const itemsUpdated = useRef(false);
    useEffect(() => {
        if(!itemsUpdated.current && allSources.length > 0){
            const existingSources = contractSources.map(x => x.source);
            const sourcesToAdd = allSources.filter(item => !existingSources.includes(item));
            if(sourcesToAdd.length >0){
                appendSource(sourcesToAdd.map(source => ({source, sourceId: ""})));
            }
            itemsUpdated.current = true;
        }
    }, [allSources, contractSources, appendSource]);

    return (
        <fieldset>
            <table className="table" style={{width: '100%'}}>
                <thead>
                <tr>
                    <th>Source</th>
                    <th>Id on source</th>
                </tr>
                </thead>
                <tbody>
                {contractSources.map((child, index) => (
                    <tr
                        key={"allSources-" + fieldIndex + "-" + child.source}
                    >
                        <td style={{alignContent: 'center'}}>{child.source}</td>
                        <td style={{alignContent: 'center'}}>
                            <ExchangeSearchInput
                                source={child.source}
                                reg={{...register(`exchangeContracts.${fieldIndex}.sourceIdMappings.${index}.sourceId`)}}
                                value={child.sourceId}
                                className={styles.fundFormInputs}/>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </fieldset>
    );
};
