import React, { useState } from 'react'
import { useCategoryGroups } from "../../query-hooks/category-query-hooks/useCategories"
import { useFundCategories } from '../../query-hooks/dashboard-query-hooks/useDashboard'
import PieChart from '../Dashboard/Charts/PieChart'
import styles from "./CategoryForm.module.scss"
import { useAuth } from '../../Hooks/useAuth'

const FundCategoriesDistribution = ({fundId}) => {
    const { darkmode } = useAuth()
    const [ group, setGroup ] = useState("")
    const categoryGroups = useCategoryGroups()
    const fundCategories = useFundCategories(fundId, group)
    const onHandleCatGroup = group => setGroup(group)
    const onHandleResponse = arr => {
      const filter = arr && arr.reduce(function (prev, curr) { return prev + curr.allocationPercentage }, 0)
      if (filter < 1) {
        return []
      } return arr
     } 
    return (
      <>
        <h6 className={darkmode ? styles.inputLabelDark : styles.inputLabel}>Choose a Category Group</h6>
        <select className={darkmode ? styles.selectInputDark : styles.selectInput} onChange={e => onHandleCatGroup(e.target.value)} defaultValue="1"> 
            <option disabled={true} value="1">Select a Category</option>
            { categoryGroups.isSuccess && categoryGroups.data.map((group, i) => (
                <option key={i} value={group}>{group}</option>
            ))} 
        </select>  
          { fundCategories.isSuccess && 
            <PieChart data={onHandleResponse(fundCategories.data)} dataKey="allocationPercentage" nameKey="name" outerRadius={160} minHeight={600} height={500} /> 
          }
      </>
    )
}
export default FundCategoriesDistribution