import React, { useEffect } from 'react'
import { Form, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import styles from '../ExchangeForm.module.scss'
import { useAuth } from "../../../Hooks/useAuth";
import useFunds from "../../../query-hooks/fund-query-hooks/useFund";
import useExchanges from "../../../query-hooks/exchange-query-hooks/useExchange";
import {Spinner} from "../../../Helpers/Helpers";

const ExchangeAccountForm = ({ onSubmit, data, hideFund, hideExchange }) => {
    const shouldHideFund = hideFund ?? false;
    const shouldHideExchanges = hideExchange ?? false;
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const { darkmode } = useAuth()
    const funds = useFunds();
    const exchanges = useExchanges()

    const loading = funds.isLoading || exchanges.isLoading;

    useEffect(() => { 
        data && reset({
          name: data.name,
          fundId: data.fundId ?? "empty",
          accountKey: data.accountKey,
          accountSecret: data.accountSecret,
          accountPrivateKey: data.accountPrivateKey,
          closedDateTime: data.closedDateTime,
          exchangeId: data.exchangeId
        });
    }, [data]);

    if(loading){
      return <Spinner />
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="justify-content-center">
                <Col xl={6} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Exchange Account Name</h6>
                    <input className={styles.fundFormInputs} placeholder="Exchange Account Name"  {...register("name", { required: true })}  />
                    {errors.name && errors.name.type === "required" && <span>This field is required</span>}
                </Col>
            </Row>
          {
            !shouldHideFund && (<Row className="justify-content-center">
              <Col xl={6} md={12} className="py-3">
                <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Fund</h6>
                {funds.isSuccess && (
                  <select className={darkmode ? styles.selectInputDark : styles.selectInput} {...register("fundId", { required: true })}>
                    {
                      // empty is set to null in onSubmit, we can't use setValueAs on a select, it only works on text input,
                      // and we can't just set value={null} because it will use the text content of the option.
                      // so we must use a different method to set null
                    }
                    <option key="hallo" value="empty">None</option>
                    {
                      funds.data.items.map(x => (<option key={x.id} value={x.id}>{x.fundName}</option>))
                    }
                  </select>
                )}
              </Col>
            </Row>)
          }
          {
            !shouldHideExchanges && exchanges.isSuccess && (<Row className="justify-content-center">
              <Col xl={6} md={12} className="py-3">
                <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Exchange</h6>
                <select className={darkmode ? styles.selectInputDark : styles.selectInput} {...register("exchangeId", { required: true })}>
                  {
                    exchanges.data.items.map(x => (<option key={x.id} value={x.id}>{x.exchangeName}</option>))
                  }
                </select>
              </Col>
            </Row>)
          }
            <Row className="justify-content-center">
                <Col xl={6} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>API Key</h6>
                    <input className={styles.fundFormInputs} placeholder="Account Key"  {...register("accountKey", { required: false })}  />
                    {errors.name && errors.name.type === "required" && <span>This field is required</span>}
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xl={6} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>API Secret</h6>
                    <input type="password" className={styles.fundFormInputs} placeholder="Account Secret"  {...register("accountSecret", { required: false })}  />
                    {errors.name && errors.name.type === "required" && <span>This field is required</span>}
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xl={6} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>API Private Key (optional)</h6>
                    <textarea className={styles.fundFormInputs} placeholder="Enter private key"  {...register("accountPrivateKey", { required: false })}  />
                    {errors.name && errors.name.type === "required" && <span>This field is required</span>}
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xl={6} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Account Closed Date</h6>
                    <input className={styles.datetime} type="date" name="booking-period" {...register("closedDateTime", { setValueAs: value => value || null, required: false })}/>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col lg={4} md={12}>
                    <input className={styles.submitButton} type="submit" />
                </Col>
            </Row>
            <Row className="d-flex text-center mt-2 mb-5">
                <Link className={styles.backLink} to={-1}>Back</Link>
            </Row>
        </Form>
    )
}
export default ExchangeAccountForm