import {
  BiDollarCircle,
  BiPoll,
  BiBitcoin,
  BiAbacus,
  BiLineChart,
  BiMoney,
  BiCog,
  BiTransfer,
  BiWallet,
  BiTask,
  BiCategory,
  BiFile,
  BiTrendingUp,
  BiTachometer
} from "react-icons/bi";

const useSidebarItems = () => {

  return [
    {
      id: 0,
      name: BiLineChart,
      to: "/main/dashboard",
      title: "Dashboard",
      allowedRoles: [ "NO_ROLE", "ADMIN", "HEADSALES", "CLIENT", "TRADER","SALES","LEADTRADER"],
    },
    {
      id: 1,
      name: BiPoll,
      to: "/main/average-prices",
      title: "Average-Prices",
      allowedRoles: ["ADMIN", "LEADTRADER", "TRADER"],
    },
    {
      id: 2,
      name: BiTask,
      to: "/main/orders",
      title: "Orders",
      allowedRoles: ["ADMIN", "LEADTRADER"],
    },
    {
      id: 3,
      name: BiAbacus,
      to: "/main/funds",
      title: "Funds",
      allowedRoles: ["ADMIN", "LEADTRADER"],
      subcontainer: "funds",
    },
    {
      id: 13,
      name: BiTachometer,
      to: "/main/marketdepth",
      title: "Market Depth",
      allowedRoles: ["ADMIN", "LEADTRADER"],
    },
    {
      id: 4,
      name: BiCategory,
      to: "/main/categories",
      title: "Categories",
      allowedRoles: ["ADMIN", "LEADTRADER", "TRADER"],
    },
    {
      id: 5,
      name: BiWallet,
      to: "/main/wallets",
      title: "Wallets",
      allowedRoles: ["ADMIN", "LEADTRADER"],
    },
    {
      id: 6,
      name: BiTransfer,
      to: "/main/exchanges",
      title: "Exchanges",
      allowedRoles: ["ADMIN", "LEADTRADER", "TRADER"],
    },
    {
      id: 7,
      name: BiMoney,
      to: "/main/banks",
      title: "Banks",
      allowedRoles: ["ADMIN", "LEADTRADER"],
    },
    {
      id: 8,
      name: BiBitcoin,
      to: "/main/cryptos",
      title: "Cryptos",
      allowedRoles: ["ADMIN", "LEADTRADER", "TRADER"],
    },
    {
      id: 9,
      name: BiDollarCircle,
      to: "/main/currencies",
      title: "Currencies",
      allowedRoles: ["ADMIN", "LEADTRADER", "TRADER"],
    },
    {
      id: 10,
      name: BiFile,
      to: "/main/reports",
      title: "Reports",
      allowedRoles: ["ADMIN", "LEADTRADER"],
    },
    {
      id: 11,
      name: BiCog,
      to: "/main/settings",
      title: "Settings",
      allowedRoles: ["ADMIN", "HEADSALES", "LEADTRADER"],
    },
    {
      id: 12,
      name: BiTrendingUp,
      to: "/main/benchmark",
      title: "Benchmark",
      allowedRoles: [ "NO_ROLE", "ADMIN", "HEADSALES", "CLIENT", "TRADER","SALES","LEADTRADER"],
    },
  ];
};

export default useSidebarItems;
