import {API_URL} from "../../settings";
import axios, {AxiosError} from "axios";
import {useQuery, UseQueryResult} from "react-query";
import {MarketDepthServiceStateModel, StateServiceType} from "../../model/query-hooks/MarketDepthServiceStateModel";

const getServiceState = async (serviceName: StateServiceType) => axios.get(API_URL + "/admin/backgroundservices/" + serviceName + "/state").then(res => res.data);

export function useMarketDepthServiceState(): UseQueryResult<MarketDepthServiceStateModel, AxiosError> {
  return useQuery(["serviceState", "marketDepth"],
    () => getServiceState("MarketDepthBackgroundService"),
    {refetchInterval: 5000});
}
