import axios from "axios";
import {useQuery, useQueryClient, useMutation} from "react-query";
import {API_URL} from "../../settings";
import Swal from "sweetalert2";
import exchangeAccountForm from "../../dashboard/Exchanges/ExchangeAccounts/ExchangeAccountForm";

/**
 * Get the list of all exchangeIds that have an implemented api in the system
 * @returns {Promise<string[]>}
 */
const fetchImplementedApis = () =>
    axios.get(`${API_URL}/exchanges/exchangeApis`).then(response => response.data);

/**
 * Fetches the list of all exchangeIds that have an implemented api in the system
 * @returns {import('react-query').UseQueryResult<string[], unknown>}
 */
export function useImplementedExchangeApis() {
    return useQuery("implementedExchangeApis", fetchImplementedApis);
}

/**
 * Function to get paginated exchanges
 * @returns {Promise<PagedResponse<Exchange[]>>}
 */
const fetchExchanges = () =>
    axios.get(`${API_URL}/exchanges?itemsPerPage=100`).then((response) => response.data);

/**
 * Fetches paginated exchanges from cache or fetch from net.
 * @returns {import('react-query').UseQueryResult<PagedResponse<Exchange[]>, unknown>}
 */
export default function useExchanges() {
    return useQuery("exchanges", fetchExchanges);
}

/**
 * Function to get all exchanges
 * @returns {Promise<Exchange[]>}
 */
const fetchAllExchanges = () =>
    axios
        .get(`${API_URL}/exchanges?page=0&itemsPerPage=100`)
        .then((response) => response.data.items);

/**
 * Fetches all exchanges from cache or fetch from net.
 * @returns {import("react-query").UseQueryResult<Exchange[], unknown>}
 */
export function useAllExchanges() {
    return useQuery("allExchanges", fetchAllExchanges);
}

// Function to get all exchange accounts
const fetchAllExchangeAccounts = () =>
    axios.get(`${API_URL}/exchangeaccounts`).then((response) => response.data);

export function useAllExchangeAccounts() {
    return useQuery("allExchangeAccounts", fetchAllExchangeAccounts);
}

// Function to get all exchange accounts for select boxes
const fetchExchangeAccountsSelect = () =>
    axios
        .get(`${API_URL}/exchangeaccounts/select`)
        .then((response) => response.data);

export function useExchangeAccountsSelect() {
    return useQuery("exchangeAccountsSelect", fetchExchangeAccountsSelect);
}

/**
 * Function to get exchange data
 * @param exchangeId
 * @returns {Promise<Exchange>}
 */
const fetchExchange = (exchangeId) =>
    axios
        .get(`${API_URL}/exchanges/${exchangeId}`)
        .then((response) => response.data);

/**
 * Fetches exchange data from cache or fetch from net.
 * @param exchangeId
 * @returns {import("react-query").UseQueryResult<Exchange, unknown>}
 */
export function useExchange(exchangeId) {
    return useQuery(["exchange", exchangeId], () => fetchExchange(exchangeId), {
        enabled: exchangeId !== undefined,
    });
}

// Function to get exchange accounts of one exchange
const fetchExchangeAccounts = (exchangeId) =>
    axios
        .get(`${API_URL}/exchanges/${exchangeId}/accounts`)
        .then((response) => response.data);

export function useExchangeAccounts(exchangeId) {
    return useQuery(
        ["exchangeAccounts", exchangeId],
        () => fetchExchangeAccounts(exchangeId),
        {enabled: exchangeId !== undefined},
    );
}

// Function to get one exchange account
const fetchExchangeAccount = (exchangeAccountId) =>
    axios
        .get(`${API_URL}/exchangeaccounts/${exchangeAccountId}`)
        .then((response) => response.data);

export function useExchangeAccount(exchangeAccountId) {
    return useQuery(
        ["exchangeAccount", exchangeAccountId],
        () => fetchExchangeAccount(exchangeAccountId),
        {enabled: exchangeAccountId !== undefined},
    );
}

//MUTATIONS

// EXCHANGE MUTATIONS
//Function to post Exchange
const postExchange = (exchange) =>
    axios
        .post(`${API_URL}/exchanges`, exchange)
        .then((response) => response.data);

export function useCreateExchange() {
    const queryClient = useQueryClient();
    return useMutation((exchange) => postExchange(exchange), {
        onSuccess: () => {
            queryClient.invalidateQueries("exchanges");
        },
    });
}

//Function to edit Exchange
const editExchange = (exchange, exchangeId) =>
    axios
        .put(`${API_URL}/exchanges/${exchangeId}`, exchange)
        .then((response) => response.data);

export function useEditExchange() {
    const queryClient = useQueryClient();
    return useMutation(
        ({exchange, exchangeId}) => editExchange(exchange, exchangeId),
        {
            onSuccess: (data, variables) => {
                queryClient.invalidateQueries("exchanges");
                queryClient.invalidateQueries(["exchanges", variables.exchangeId]);
            },
        },
    );
}

//Function to delete Exchange
const deleteExchange = (exchangeId) =>
    axios
        .delete(`${API_URL}/exchanges/${exchangeId}`)
        .then((response) => response.data);

export function useDeleteExchange() {
    const queryClient = useQueryClient();
    return useMutation(({exchangeId}) => deleteExchange(exchangeId), {
        onSuccess: async () => queryClient.invalidateQueries("exchanges"),
        onError: async (error) =>
            Swal.fire(error.response.data.errors[0].description),
    });
}

// Message object inserted in delete message
const deleteMessageObject = {
    title: "Are you sure you want to delete this Exchange?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#6d9e93",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
};

export const confirmationAlertDeleteExchange = (
    event,
    exchangeId,
    mutation,
) => {
    event.preventDefault();
    Swal.fire(deleteMessageObject).then(
        (result) =>
            result.isConfirmed && mutation.mutate({exchangeId: exchangeId}),
    );
};

//region EXCHANGE CONTRACT MUTATIONS
// Message object inserted in delete message
const deleteContractMessageObject = {
    title: "Are you sure you want to delete this Exchange Contract?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#6d9e93",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
};
export const confirmationAlertDeleteExchangeContract = (
    event,
    exchangeContractId,
    mutation,
) => {
    event.preventDefault();
    Swal.fire(deleteContractMessageObject).then(
        (result) =>
            result.isConfirmed && mutation.mutate({exchangeContractId}),
    );
};

const fetchExchangeContracts = ()=> axios.get(`${API_URL}/exchanges/contracts`).then((response)=>response.data);

export function useExchangeContracts(){
    return useQuery("exchangeContracts", fetchExchangeContracts);
}

//Function to delete Exchange Contract
const deleteExchangeContract = (exchangeContractId) =>
    axios
        .delete(`${API_URL}/exchanges/contracts/${exchangeContractId}`)
        .then((response) => response.data);

export function useDeleteExchangeContract() {
    const queryClient = useQueryClient();
    return useMutation(({exchangeContractId}) => deleteExchangeContract(exchangeContractId), {
        onSuccess: async () =>
            await queryClient.invalidateQueries("exchanges")
        ,
        onError: async (error) =>
            Swal.fire(error.response.data.errors[0].description),
    });
}

//endregion END EXCHANGE CONTRACT MUTATIONS

//region EXCHANGE ACCOUNT MUTATIONS

//Function to post Exchange Account
const postExchangeAccount = (exchangeAccount) =>
    axios
        .post(`${API_URL}/exchangeaccounts`, exchangeAccount)
        .then((response) => response.data);

export function useCreateExchangeAccount() {
    const queryClient = useQueryClient();
    return useMutation(
        (exchangeAccount) => postExchangeAccount(exchangeAccount),
        {
            onSuccess: async () => queryClient.invalidateQueries( "exchangeAccounts"),
            onError: async (error) => {
                Swal.fire(error.response.data.errors[0].description);
            }
        },
    );
}

//Function to edit Exchange Account
const editExchangeAccount = (exchangeAccount, exchangeAccountId) =>
    axios
        .put(`${API_URL}/exchangeaccounts/${exchangeAccountId}`, exchangeAccount)
        .then((response) => response.data);

export function useEditExchangeAccount() {
    const queryClient = useQueryClient();
    return useMutation(
        ({exchangeAccount, exchangeAccountId}) =>
            editExchangeAccount(exchangeAccount, exchangeAccountId),
        {
            // invalidate exchange account but also fund cache when exchange account is added to fund
            onSuccess: async () => queryClient.invalidateQueries( "exchangeAccounts"),
            onError: async (error) =>
                Swal.fire(error.response.data.errors[0].description),
        },
    );
}

//Function to delete Exchange
const deleteExchangeAccount = (exchangeAccountId) =>
    axios
        .delete(`${API_URL}/exchangeaccounts/${exchangeAccountId}`)
        .then((response) => response.data);

export function useDeleteExchangeAccount() {
    const queryClient = useQueryClient();
    return useMutation(
        ({exchangeAccountId}) => deleteExchangeAccount(exchangeAccountId),
        {
            onSuccess: async () => queryClient.invalidateQueries("exchangeAccounts"),
            onError: async (error) =>
                Swal.fire(error.response.data.errors[0].description),
        },
    );
}

// Message object inserted in delete message
const deleteMessageExchangeAccount = {
    title: "Are you sure you want to delete this Exchange Account?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#6d9e93",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
};

export const confirmationAlertDeleteExchangeAccount = (
    event,
    exchangeAccountId,
    mutation,
) => {
    event.preventDefault();
    Swal.fire(deleteMessageExchangeAccount).then(
        (result) =>
            result.isConfirmed &&
            mutation.mutate({exchangeAccountId: exchangeAccountId}),
    );
};

//endregion exchangeaccounts